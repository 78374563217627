import React, { useState,useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../../../hooks/useModal';
import { DatePickerABX } from '../../components/pickers/DatePicker';
import { useVehiculos } from '../../../hooks/useVehiculos';
import { useClases } from '../../../hooks/useClases';
import { useUsuarios } from '../../../hooks/useUsuarios';
import { useMatricula } from '../../../hooks/useMatricula';
import FloatingButton from '../../components/FloatingButton';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import { notify } from '../../../utils/utils';
import { useTipoClases } from '../../../hooks/useTipoClases';


export const Programacion = () => {
    const { usuarios,listarUsuarios } = useUsuarios()
    const { matricula, listarMatriculas, matriculas, obtenerMatricula, } = useMatricula()
    const { vehiculos, listarVehiculos, vehiculo, obtenerVehiculo,} = useVehiculos()
    const { tiposVehiculos } = useTiposVehiculos()
    const { clases, listarClases, guardarEditarClase, clase, editarValorClase, obtenerClase, spinner,isOpenModalProgramacion , openModalProgramacion, closeModalProgramacion } = useClases()
    const { tipoClases } = useTipoClases()
    const [ numeroHora, setNumeroHora ] = useState(0)
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const usuariosEstudiantes = usuarios.filter(u => u.CodRol == 4); //4 codEstudiante
    const usuariosInstructores = usuarios.filter(u => u.CodRol == 3); //3 codInstructor
    const [selectedDate, setSelectedDate] = useState(today.toISOString().slice(0, 10));
    const [ fechaHoraInicioProgramado, setFechaHoraInicioProgramado ] = useState(new Date(selectedDate))
    const [ fechaHoraFinProgramado, setFechaHoraFinProgramado ] = useState( new Date(selectedDate))
    const [ eventosCalendario, setEventosCalendario ] = useState([])

    const [ tipoVehiculoSeleccionado, setTipoVehiculoSeleccionado ] = useState(100)
    const [ vehiculosFiltrados, setVehiculosFiltrados ] = useState(vehiculos)
    const [ estudianteSeleccionado, setEstudianteSeleccionado ] = useState('')
    const [ matriculaFiltrada,setMatriculaFiltrada ] = useState({})
    const [ tipoClaseFiltrado, setTipoClaseFiltrado ] = useState({})
    const [ noHayMatricula, setNoHayMatricula ] = useState(false)
    const [ verCalendario, setVerCalendario ] = useState(true)
    const classNameInput = "text-gray-800 h-[30px] rounded px-4 w-full  text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   

    const [eventTitle, setEventTitle] = useState('');
    const [eventTime, setEventTime] = useState('');


    const getEventCountForDate = (date) => {
        return clases.filter(evento => {
            const eventDate = new Date(evento.FechaHoraInicioProgramado).toISOString().slice(0, 10);
            return eventDate === date;
        }).length;
    };

    useEffect(()=>{ 
        editarValorClase("NumeroHora", numeroHora)
    },[numeroHora])


    useEffect(()=>{
        console.log("estudianteSeleccionado-> ",estudianteSeleccionado)

        const matriculasFiltradas = matriculas.filter(m => m.Estudiante === estudianteSeleccionado);

        if(matriculasFiltradas?.length === 0 && estudianteSeleccionado ){
            setNoHayMatricula(true)
            notify("No se encontró una matricula activa para el estudiante",'error')
        }else{
            setNoHayMatricula(false)
        }

        const matriculaParaAsignar = matriculasFiltradas?.length > 0 ? matriculasFiltradas[0] : null;

        setMatriculaFiltrada(matriculaParaAsignar)
        console.log("matriculaFiltrada-> ",matriculasFiltradas?.length > 0 ? matriculasFiltradas[0] : null)
        
        setTipoClaseFiltrado(tipoClases?.filter(tc => tc?.CodTipoClase == matriculaParaAsignar?.CodTipoClase)[0]);
        editarValorClase("CodMatricula",matriculaParaAsignar?.CodMatricula)
        console.log("TipoClaseFiltrado -> ", tipoClases?.filter(tc => tc?.CodTipoClase == matriculaParaAsignar?.CodTipoClase)[0])
        setTipoVehiculoSeleccionado(tipoClases?.filter(tc => tc?.CodTipoClase == matriculaParaAsignar?.CodTipoClase)[0]?.CodTipoVehiculo)
    },[estudianteSeleccionado])

    useEffect(()=>{
        if(matriculaFiltrada){
            setNumeroHora((matriculaFiltrada?.CantidadHoras - matriculaFiltrada?.CantidadHorasRestantes) + 1)
        }else{
            setNumeroHora(0)
        }
    },[matriculaFiltrada])
    

    // Opciones de estilo para el calendario
    const eventRender = ({ event, el }) => {
        const eventDate = new Date(event.start).toISOString().slice(0, 10);
        const eventCount = getEventCountForDate(eventDate);

        if (eventCount >= 10) {
            el.classList.add('many-events');
        } else if (eventCount >= 3 && eventCount < 10) {
            el.classList.add('medium-events');
        } else if (eventCount === 1 || eventCount === 2) {
            el.classList.add('few-events');
        }
    };
     // Establece los eventos de FullCalendar
    const calendarEvents = eventosCalendario.map(evento => ({
        id: evento.id,
        title: evento.title,
        start: evento.start,
        end: evento.end,
        extendedProps: evento.extendedProps,
    }));
    useEffect(()=>{
        console.log("VEHICULOS -> ", vehiculos)
        if (tipoVehiculoSeleccionado == 0) {
            setVehiculosFiltrados(vehiculos);
        } else {
            setVehiculosFiltrados(vehiculos.filter(v => v.CodTipoVehiculo == tipoVehiculoSeleccionado));
        }
    },[tipoVehiculoSeleccionado])

    useEffect(()=>{
        console.log("CAMBIO Y ACTUALIZO EL SELECTED DATE -> ", selectedDate)
        setFechaHoraInicioProgramado(selectedDate)
        setFechaHoraFinProgramado(selectedDate)
    },[selectedDate])


    useEffect(()=>{
        editarValorClase("FechaHoraInicioProgramado", fechaHoraInicioProgramado)
        editarValorClase("FechaHoraFinProgramado", fechaHoraFinProgramado)
    },[fechaHoraInicioProgramado,fechaHoraFinProgramado])


    useEffect(() => {
        console.log("CARGAR EVNETOS-> ", clases)
        const eventosFormateados = clases.map(clase => ({
            id: clase.CodClase,
            title: `${clase?.TipoClase || clase.Estado}`,
            start: clase.FechaHoraInicioProgramado,
            end: clase.FechaHoraFinProgramado,
            extendedProps: {
            instructor: clase.Instructor,
            tipoClase: clase.NombreTipoClase,
            lugarInicio: clase.LugarInicio,
            lugarFin: clase.LugarFin,
            }
        }));
        console.log("eventosFormateados->",eventosFormateados)
        setEventosCalendario(eventosFormateados);
    }, [clases]);



    const handleDateClick = (arg) => {
        setSelectedDate(arg.dateStr);
    };

    const renderEventContent = (eventInfo) => {
        console.log("renderEventContent-> ",eventInfo)
        return (
            <>
                {/* <span>{eventInfo.event.HoraInicio}</span> */}
                {/* <span>{eventInfo.timeText} - {eventInfo.event.title}</span> */}
                <div> {eventInfo.event.extendedProps.tipoClase}</div>
                {/* <div>Inicio: {eventInfo.event.extendedProps.lugarInicio}, Fin: {eventInfo.event.extendedProps.lugarFin}</div> */}

                {/* Puedes añadir más detalles aquí, como instructor, estudiante, etc. */}
                {/* Asegúrate de que estos detalles estén incluidos en la propiedad `extendedProps` del evento */}
            </>
        );
    };


    // La lógica para filtrar y mostrar eventos según la fecha seleccionada puede permanecer igual
    // const eventsForSelectedDate = clases.filter(c => c.FechaHoraInicioProgramado == selectedDate);

    const eventsForSelectedDate = clases.filter(evento => {
        // Convertir la fecha del evento al formato YYYY-MM-DD para comparación
        const eventDate = new Date(evento.FechaHoraInicioProgramado).toISOString().slice(0, 10);
        return eventDate === selectedDate;
    });


    const handleHoraInicioChange = (e) => {
        const horaInicio = e.target.value; // "HH:MM"
        setFechaHoraInicioProgramado(prevState => {
            // prevState es la fecha seleccionada actual en formato "YYYY-MM-DD"
            const fechaHoraInicioString = `${selectedDate}T${horaInicio}:00`;
            // Crea un objeto Date directamente desde la cadena, asumiendo zona horaria local
            const fechaHoraInicio = new Date(fechaHoraInicioString);
    
            console.log("nueva FechaHoraInicio con manejo directo de string -> ", fechaHoraInicio);
            editarValorClase("FechaHoraInicioProgramado", fechaHoraInicio);
            return fechaHoraInicio;
        });
    };
    
    const handleHoraFinChange = (e) => {
        const horaFin = e.target.value; // "HH:MM"
        setFechaHoraFinProgramado(prevState => {
            // No es necesario usar prevState aquí porque la fecha no cambia
            const fechaHoraFinString = `${selectedDate}T${horaFin}:00`;
            // Crea un objeto Date directamente desde la cadena, asumiendo zona horaria local
            const fechaHoraFin = new Date(fechaHoraFinString);
    
            console.log("nueva FechaHoraFin con manejo directo de string -> ", fechaHoraFin);
            editarValorClase("FechaHoraFinProgramado", fechaHoraFin);
            return fechaHoraFin;
        });
    };
    
    
    
    
    return (
        <>
            {verCalendario &&<div className="h-[60%] overflow-hidden text-gray-500 ">
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    locale={esLocale}
                    height="100%"
                    dateClick={handleDateClick}
                    events={eventosCalendario}
                    // events={events.map(event => ({
                    //     ...event,
                    //     start: `${event.date}T${event.HoraInicio}`,
                    //     end: `${event.date}T${event.HoraFinal}`,
                    //     title: `${event.title} - ${event.Estudiante}`
                    // }))}
                    eventContent={renderEventContent} 
                    eventLimit={true} // Puedes especificar un número específico o true para usar el límite predeterminado

                    customButtons={{
                        myCustomButton: {
                            text: 'custom!',
                            click: function() {
                                alert('clicked the custom button!');
                            }
                        }
                    }}
                    headerToolbar={{
                        left: 'prev,next today myCustomButton',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek'//dayGridDay
                    }}
                    fixedWeekCount={false} // Ajusta el número de semanas mostradas

                    // Configura el resto de tu FullCalendar según necesites
                />
            </div>}
                <div className='flex text-2xl -mb-2 bg-[#113b67] rounded justify-start text-center text-white mt-2'>

                    <button 
                        onClick={()=>setVerCalendario(!verCalendario)}
                        className={`mx-5 ${verCalendario?  ' fa fa-angle-up ' : ' fa fa-angle-down '} hover:text-green-500 `}
                        > 
                    </button>
                    <h2 className=''>Programación día: {selectedDate? `${selectedDate.split("-")[2]}/${selectedDate.split("-")[1]}/${selectedDate.split("-")[0]}` : 'Seleccione un día'}</h2>
                </div>

                <div className={`containerScroll overflow-y-auto ${verCalendario? 'max-h-[35%]' : 'max-h-[90%]'} mt-2  top-0 bottom-0 left-0 right-0 contenedor-tabla`}>
                    <table id='tabla-clases' className="tableResponsive ">
                        <thead>
                            <tr>
                                {/* <th className="w-[10px]">N</th> */}
                                <th className="text-center w-[90px] ">H. Inicio</th>
                                <th className="text-center w-[90px] ">H. Fin</th>
                                <th className="text-center">TIPO CLASE</th>
                                <th className="text-center">ESTUDIANTE</th>
                                <th className="text-center">N° CLASE</th>
                                <th className="text-center">PLACA</th>
                                <th className="text-center">INSTRUCTOR</th>
                                <th className="w-[100px]">ESTADO</th>
                                <th className="w-[100px]"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {   
                                eventsForSelectedDate.length > 0 
                                ? eventsForSelectedDate.map((tc, i) => {
                                    return (
                                        <tr key={tc.codTipoClase}>
                                            {/* <td>{ i + 1}</td> */}
                                            <td className="text-center">{tc.FechaHoraInicioProgramado.split('T')[1].split(":")[0]}:{tc.FechaHoraInicioProgramado.split('T')[1].split(":")[1]}</td>
                                            <td className="text-center">{tc.FechaHoraFinProgramado.split('T')[1].split(":")[0]}:{tc.FechaHoraFinProgramado.split('T')[1].split(":")[1]}</td>
                                            <td className="text-center">{tc.NombreTipoClase}</td>
                                            <td className="text-center">{tc.Estudiante}</td>
                                            <td className="text-center">1</td>
                                            <td className="text-center">{tc.Placa}</td>
                                            <td className="text-center">{tc.Instructor}</td>
                                            <td className="text-center">
                                                <span 
                                                    className={`
                                                        ${
                                                            tc.CodEstado == 1? ' bg-yellow-600 ': 
                                                            tc.CodEstado == 2? ' bg-orange-600 ': 
                                                            tc.CodEstado == 3? ' bg-green-600 ': 
                                                            tc.CodEstado == 4? ' bg-red-600 ' : 
                                                            ' bg-gray-500 '
                                                        } 
                                                        text-white rounded px-2 p-1 text-[14px] 
                                                    `}
                                                >
                                                    {tc.NombreEstado}
                                                </span> 
                                            </td>

                                            <td className="text-center w-[100px]" >
                                                <button onClick={() => {console.log("CodTipoClase");}} className="text-white bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                            {/* </td>    
                                            <td className="text-center w-[10px]" > */}
                                                <button  onClick={()=> console.log("tc.CodTipoClase")} className="text-white bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                                : <tr><td colSpan="9" className="text-center">No hay información que mostrar con los parámetros seleccionados.</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                    <FloatingButton onButtonClick={()=>obtenerClase()}/>



                <Modal 
                isOpen={isOpenModalProgramacion} closeModal={closeModalProgramacion}
                action={() => {guardarEditarClase();listarMatriculas();listarUsuarios()}}
                title={`Programación ${selectedDate.split('-')[2]}/${selectedDate.split('-')[1]}/${selectedDate.split('-')[0]}`}
            >
                    <div className="flex flex-col items-center flex-wrap justify-center">
                        {/* <div className='text-center text-2xl underline mb-2 '>{selectedDate.split('-')[2]}/{selectedDate.split('-')[1]}/{selectedDate.split('-')[0]}</div> */}
                        <div className="w-[500px] px-4">
                            <div className="flex flex-col justify-between w-full form-content">                        
                                
                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Estudiante: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select 
                                            className={`${classNameInput} `} 
                                            defaultValue={matricula.Estudiante} 
                                            onChange={(e) => {editarValorClase("Estudiante", e.target.value);setEstudianteSeleccionado(e.target.value)}} 
                                        >
                                            <option className='' value={0}>-- Seleccione --</option>
                                            {
                                                usuariosEstudiantes?.map(u => {
                                                    return <option key={u.CodUsuario} value={u.Usuario}>{u.Usuario} ({u.Nombres})</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Número de hora: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input 
                                            defaultValue={numeroHora}
                                            value={numeroHora}
                                            className={classNameInput}
                                            disabled
                                        />
                                        <label>de</label>

                                        <input 
                                            defaultValue={matriculaFiltrada?.CantidadHoras}
                                            value={matriculaFiltrada?.CantidadHoras}
                                            className={classNameInput}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Pago Pendiente: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input 
                                            defaultValue={(matriculaFiltrada?.Monto - matriculaFiltrada?.MontoPagado) || 0}
                                            value={(matriculaFiltrada?.Monto - matriculaFiltrada?.MontoPagado) || 0}
                                            className={classNameInput}
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Tipo de vehículo: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                    <select 
                                        className={`${classNameInput} `} 
                                        defaultValue={tipoClaseFiltrado?.CodTipoVehiculo} 
                                        value={tipoClaseFiltrado?.CodTipoVehiculo} 
                                        onChange={(e) => {setTipoVehiculoSeleccionado(e.target.value)}} 
                                        disabled
                                    >
                                        {!tipoVehiculoSeleccionado && <option className='' value={100}> Seleccione </option>}
                                        <option className='' value={0}> Cualquiera </option>
                                        {
                                            tiposVehiculos?.map(r => {
                                                return <option key={r.CodTipoVehiculo} value={r.CodTipoVehiculo}>{r.NombreTipoVehiculo}</option>
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>

                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Vehículo: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                    <select 
                                        className={`${classNameInput} `} 
                                        defaultValue={matricula.CodVehiculo} 
                                        onChange={(e) => {editarValorClase("CodVehiculo", e.target.value)}} 
                                        disabled={noHayMatricula}
                                    >
                                        <option value={0}>-- Seleccione --</option>
                                        {
                                            vehiculosFiltrados.map((v,i)=> {
                                                return (
                                                    <option key= {v.CodVehiculo} value={v.CodVehiculo}>[{v.Placa}] {v.Marca} {v.Modelo}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    </div>
                                </div>

                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Instructor: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <select 
                                            className={`${classNameInput} `} 
                                            defaultValue={matricula.Instructor} 
                                            onChange={(e) => {editarValorClase("Instructor", e.target.value)}} 
                                            disabled={noHayMatricula}
                                        >
                                            <option className='' value={0}>-- Seleccione --</option>
                                            {
                                                usuariosInstructores?.map(u => {
                                                    return <option key={u.CodUsuario} value={u.Usuario}>{u.Usuario} ({u.Nombres})</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                            

                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Hora Inicio: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        {/* <input onChange={(e)=>editarValorClase("horaInicio",e.target.value)}  type='time' className={`${classNameInput} text-center center justify-center `}/> */}
                                        <input 
                                            onChange={handleHoraInicioChange}  
                                            type='time' 
                                            className={`${classNameInput} text-center center justify-center `}
                                            disabled={noHayMatricula}
                                        />

                                        {/* <DatePickerABX data-for={'FechaInicio'} date={fechaHoraInicioProgramado} setDate={setFechaHoraInicioProgramado}/> */}
                                    </div>
                                </div>
                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Hora Final: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                    {/* <input onChange={(e)=>editarValorClase("horaInicio",e.target.value)}  type='time' className={`${classNameInput} text-center center justify-center `}/> */}
                                    <input 
                                        onChange={handleHoraFinChange}  
                                        type='time' 
                                        className={`${classNameInput} text-center center justify-center `}
                                        disabled={noHayMatricula}
                                    />

                                        {/* <DatePickerABX data-for={'FechaInicio'} date={fechaHoraFinProgramado} setDate={setFechaHoraFinProgramado}/> */}
                                    </div>
                                </div>
                                <div className='mb-3 flex justify-between lg:flex-col lg:w-full'>
                                    <label>Horas: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input 
                                            className={classNameInput}
                                            disabled    
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                </Modal>

        </>
    );
};
