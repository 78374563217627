import React, { useEffect, useState, useRef, useContext } from 'react';
import { UserContext } from '../../../context/provider/UserProvider';
import { useTipoClases } from '../../../hooks/useTipoClases';
import { useMatricula } from '../../../hooks/useMatricula';
import { useUsuarios } from '../../../hooks/useUsuarios';
import { DatePickerABX } from '../../components/pickers/DatePicker';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import LinesChart from '../../components/LinesChart';
import { useVehiculos } from '../../../hooks/useVehiculos';



export const ReporteVehiculos = () => {
    
    const {
        vehiculos,
        listarVehiculos,
        guardarEditarVehiculo,
        vehiculo, 
        editarValorVehiculo, 
        obtenerVehiculo, 
        isOpenModalRegistrar, 
        closeModalRegistrar, 
        setFotoFile,
        eliminarPersona, 
        isOpenModalEliminar, 
        openModalEliminar,
        closeModalEliminar, 
        vehiculoEliminar,
        fotoUrlVehiculo,
        setFotoUrlVehiculo, 
        guardarVehiculoFoto,
    } = useVehiculos()


    const [ fechaPago, setFechapago ] = useState( new Date())
    const [ fechaMatricula, setFechaMatricula ] = useState( new Date())
    const { stateUser }  = useContext(UserContext)


    return (
        <>
            <h1 className=' text-2xl underline mt-5 ml-5'>Reporte de Vehículos</h1>
            <div className="containerScroll  h-max-[40%] mt-2  relative top-0 bottom-0 left-0 right-0 contenedor-tabla">
            <table id='tabla-personas' className="tableResponsive ">
                    <thead>
                        <tr>
                            {/* <th className="w-[40px] text-center">N</th> */}
                            <th className='w-2'></th>
                            <th className='text-center'>PLACA</th>
                            <th>HORAS</th>
                            <th>INSTRUCTOR</th>
                            <th>ESTUDIANTES</th>
                            {/* <th>VEHICULO</th> */}
                            {/* <th>USUARIO</th> */}
                            {/* <th></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {  
                            vehiculos.length > 0
                            ? vehiculos.map((v,i)=> {
                                return (
                                <tr key={v.CodVehiculo} >
                                    {/* <td>{ i + 1 }</td> */}
                                    <td className='w-[70px] '>
                                        <img className='rounded-full' src={v.Foto}/>
                                    </td>
                                    <td className="text-center">{v.Placa}</td>
                                    <td className="text-center">5</td>
                                    <td className="text-center">Henry Cerna</td>
                                    <td className="text-center">3</td>
                                    {/* <td className="text-center">{u.Usuario}</td> */}
                                </tr>
                                )
                            })
                            : <tr><td colSpan={'9'} className='text-center'>No hay información para mostrar por el momento</td></tr>
                        }
                        
                    </tbody>
                </table>
            </div>

            {/* <div className='flex'>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}>{matriculas?.length}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Total Matriculas
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> S/. {0}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Monto Total
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> {0}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Pagos Completos
                        </p>
                    </div>
                </div>
                <div className={`m-2 h-[90px] text-white rounded-lg bg-slate-800 shadow-md w-full p-2 relative flex flex-col items-right`}>
                    <div className=" mt-2 text-right">
                        <p className={`text-3xl font-bold`}> {'usuario'}</p>
                        <p className="text-xl text-gray-400 border-t border-gray-500">
                            Mayor Matriculas
                        </p>
                    </div>
                </div>
            </div> */}
                

                
{/* 
            <div className='h-[30%] w-full flex'>
            </div> */}

            
        </>
    )


}