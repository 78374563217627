import { useState, useEffect, useContext } from 'react';
import { notify } from '../utils/utils';
import { useModal } from './useModal';
import { AuthFetch, Fetch } from '../services/api';
import { UserContext } from '../context/provider/UserProvider';
import AutoDefault from '../assets/images/autoDefault.jpg'

const vehiculoDefault = {
    CodVehiculo: 0,
    Placa: "",
    Marca: "",
    CodTipoVehiculo: 0,
    Año: "",
    TarjetaPropiedad: "",
    NMotor: "",
    Categoria: "",
    Modelo: "",
    Carroceria: "",
    Combustible: "",
    Serie: "",
    Color: "",
    PesoBruto: "",
    PesoSeco: "",
    Ancho: "",
    Asientos: "",
    Cilindros: "",
    Puertas: "",
    CargaUtil: "",
    Longitud: "",
    Altura: "",
    Pasajeros: "",
    Ejes: "",
    Foto: "",
}

const urlVehiculos = process.env.REACT_APP_PROMETHEUS_API + "/vehiculos";

export const useVehiculos = () => {
    const [ vehiculos, setVehiculos ] = useState([]);
    const [ vehiculo, setVehiculo ] = useState(vehiculoDefault);
    const [ isOpenModalRegistrar, openModalRegistrar, closeModalRegistrar ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    const [ fotoFile, setFotoFile ] = useState(AutoDefault); 
    const [ fotoUrlVehiculo, setFotoUrlVehiculo ] = useState(AutoDefault);

    const {stateUser} = useContext(UserContext)

    useEffect(() => {
        listarVehiculos();
    }, []);

    const listarVehiculos = async () => {
        console.log("INGRESO A LA FUNCION LISTAR VEHICULOS ")
        const response = await AuthFetch({
            url: urlVehiculos + '?'+ new URLSearchParams({
                database  : stateUser.bdEscuela,
            })
        });
        if (response.isValid) {
            console.log("RESPONSE VEHICULOS -> ", response.content)
            setVehiculos(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerVehiculo= async (CodVehiculo) => {
        if (CodVehiculo) {
            const response = await AuthFetch({
                url: urlVehiculos + '/obtenerVehiculo?'+ new URLSearchParams({
                    cod_vehiculo : CodVehiculo,
                    database  : stateUser.bdEscuela,
                })
            });
            console.log("RESPONSE OBTENER vehiculo -> ", response)
            if (response.isValid) {
                setVehiculo(response.content);
                setFotoUrlVehiculo(response.content.Foto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setVehiculo(vehiculoDefault);
        }
        openModalRegistrar();
    }

    const vehiculoEliminar = async (CodVehiculo) =>{
        if (CodVehiculo) {
            const response = await AuthFetch({
                url: urlVehiculos + '/' + CodVehiculo
            });

            if (response.isValid) {
                setVehiculo(response.content);
                // verFotoUrlPersona(response.content.nombreCarpetaFoto, response.content.nombreFoto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setVehiculo(vehiculoDefault);
            // setFotoUrlPersona(fotoDefault)
        }
        openModalEliminar();

    }

    const eliminarPersona = async (codPersona)=>{
        const response = await AuthFetch({
            url: urlVehiculos + '/eliminarVehiculos?' + new URLSearchParams({
                CodPersona: codPersona
            }),
            method:'PUT'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarVehiculos()
        }
        closeModalEliminar()
    }
    
    const guardarVehiculoFoto = async () => {
        if (fotoFile != null){

            const fotoData = new FormData();
            fotoData.append('archivo', fotoFile);
            const response = await Fetch({
                url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
                    NombreCarpeta:"vehiculos",
                    NombreArchivo: `${vehiculo.Placa}`,
                }),
                headers:{
                    contentType: 'image/*'
                    // 'Content-Type':'image/*'
                },
                method:'POST',
                body : fotoData
            });
            if (response.isValid) {
                setFotoFile(null)
                vehiculo.Foto=`${process.env.REACT_APP_PROMETHEUS_MINIO}/api/Archivo/verImagen?NombreCarpeta=vehiculos&NombreImagen=${vehiculo.Placa}`
                guardarEditarVehiculo()
            }else{
                notify(response.exceptions[0].description, 'error');
            }
        }else{
            guardarEditarVehiculo()
        }
    }

    const guardarEditarVehiculo = async () => {
        console.log("ENTRO AL GUARDAREDITARVEHICULO -> ", vehiculo)
        const fotoData = new FormData();
        fotoData.append('foto', fotoFile);
        // if (fotoFile === null && persona.codPersona === 0){
        //         notify('Tiene que seleccionar una Imagen','error')
        //         return;
        // }else 
        if(fotoFile !== null && fotoFile.size > 1049456){
                notify('El tamaño máximo de la imagen debe ser de 1MB ','error')
                return;
        }else{
                const esGuardar = vehiculo.CodVehiculo == 0;
                const response = await AuthFetch({
                    url: urlVehiculos,
                    method: esGuardar ? 'POST' : 'PUT',
                    body : JSON.stringify({
                        ...(!esGuardar && { CodVehiculo: vehiculo.CodVehiculo }),
                        Placa:vehiculo.Placa,
                        Marca:vehiculo.Marca,
                        CodTipoVehiculo: vehiculo.CodTipoVehiculo,
                        Año:vehiculo.Año,
                        TarjetaPropiedad:vehiculo.TarjetaPropiedad,
                        NMotor:vehiculo.NMotor,
                        Categoria:vehiculo.Categoria,
                        Modelo:vehiculo.Modelo,
                        Carroceria:vehiculo.Carroceria,
                        Combustible:vehiculo.Combustible,
                        Serie:vehiculo.Serie,
                        Color:vehiculo.Color,
                        PesoBruto:vehiculo.PesoBruto,
                        PesoSeco:vehiculo.PesoSeco,
                        Ancho:vehiculo.Ancho,
                        Asientos:vehiculo.Asientos,
                        Cilindros:vehiculo.Cilindros,
                        Puertas:vehiculo.Puertas,
                        CargaUtil:vehiculo.CargaUtil,
                        Longitud:vehiculo.Longitud,
                        Altura:vehiculo.Altura,
                        Pasajeros:vehiculo.Pasajeros,
                        Ejes:vehiculo.Ejes,
                        Foto:vehiculo.Foto,
                        BDEscuela:stateUser.bdEscuela
                    })
                });
                console.log("RESPONSE -> ", response)
                notify(response.content, response.isValid ? 'success' : 'error');
                if (response.isValid) {
                    setVehiculo(vehiculoDefault);
                    await listarVehiculos();
                    closeModalRegistrar()
                }
        }
    }



    const editarValorVehiculo = (key, value) => {
        console.log("EDITAR VALOR VEHICULO -> ", key,value)
        setVehiculo(vehiculo => {
            return {
                ...vehiculo,
                [key]: value
            }
        });
    }

    // const verFotoUrlPersona = (nomCarpeta,nombreFoto) => {
    //     if (nombreFoto === ''){
    //         setFotoUrlPersona(fotoDefault); 
    //     }else {
    //         setFotoUrlPersona(url => urlBasePlamin + `/Minio?nombreCarpetaFoto=${nomCarpeta}&nombreFoto=${nombreFoto}`);
    //     }
    // }

    return {
        vehiculos,
        listarVehiculos,
        guardarEditarVehiculo,
        vehiculo, 
        editarValorVehiculo, 
        obtenerVehiculo, 
        isOpenModalRegistrar, 
        closeModalRegistrar, 
        setFotoFile,
        eliminarPersona, 
        isOpenModalEliminar, 
        openModalEliminar,
        closeModalEliminar, 
        vehiculoEliminar,
        fotoUrlVehiculo,
        setFotoUrlVehiculo, 
        guardarVehiculoFoto
    }
}