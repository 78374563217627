import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlClases = process.env.REACT_APP_PROMETHEUS_API + "/clase";

export const useClases = () => {
    const { stateUser , signOut } = useContext(UserContext);

    const claseDefault = {
        CodClase: 0,
        FechaHoraInicioProgramado: new Date(),
        FechaHoraFinProgramado: new Date(),
        LugarInicio: 0,
        LugarFin: 0,
        Instructor: '',
        Estudiante: '',
        CodEstado: 1, //pendiente
        Estado: 'Pendiente',
        CodMatricula: 0,
        NumeroHora:0,
        CodRecorrido: 0,
        CodVehiculo: 0,
        FechaHoraIncioEjecutado: new Date(),
        FechaHoraFinEjecutado: new Date(),
        codEmpresa: stateUser.codEmpresa
    }

    const [ clases, setClases ] = useState([]);
    const [ clase, setClase ] = useState(claseDefault);
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    // const [ isOpen, openModal, closeModal] = useModal();
    const [ isOpenModalProgramacion , openModalProgramacion, closeModalProgramacion ] = useModal(false)  
    

    useEffect(() => {
        listarClases();
    }, []);

    const listarClases = async () => {
        console.log("INGRESO A LA FUNCION LISTAR CLASES ")
        const response = await AuthFetch({
            url: urlClases + '?'+ new URLSearchParams({
                cod_empresa : stateUser.codEmpresa
            })
        });
        if (response.isValid) {
            console.log("RESPONSE CLASES -> ", response.content)
            setClases(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const obtenerClase= async (codClase) => {
        console.log("OBTENER CLASE -> ", codClase)
        if (codClase) {
            const response = await AuthFetch({
                url: urlClases + '/obtenerClase?'+ new URLSearchParams({
                    cod_clase : codClase,
                    cod_empresa : stateUser.codEmpresa,
                })
            });
            console.log("RESPONSE OBTENER DOCUMENTO -> ", response)
            if (response.isValid) {
                setClase(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setClase(claseDefault);
        }
        openModalProgramacion();
    }
    
    function toLocalISOString(date) {
        const offset = date.getTimezoneOffset() * 60000; // Convertir offset a milisegundos
        const localISOTime = (new Date(date - offset)).toISOString().slice(0, -1);
        return localISOTime;
    }
    
    
    
    const guardarEditarClase = async () => {
        console.log("DATOS PARA EL REGISTRO -> ", clase)
        const esGuardar = clase.CodClase == 0;
        const response = await AuthFetch({
            url: urlClases ,
            method: esGuardar ? 'POST' : 'PUT',
            body : JSON.stringify({
                ...(!esGuardar && { CodClase: clase.CodClase }),
                FechaHoraInicioProgramado:toLocalISOString(new Date(clase.FechaHoraInicioProgramado)),
                FechaHoraFinProgramado:toLocalISOString(new Date(clase.FechaHoraFinProgramado)) ,
                LugarInicio:clase.LugarInicio ,
                LugarFin:clase.LugarFin ,
                Instructor:clase.Instructor  ,
                Estudiante:clase.Estudiante  ,
                CodEstado:clase.CodEstado ,
                Estado:clase.Estado ,
                NumeroHora: clase.NumeroHora,
                CodMatricula:clase.CodMatricula ,
                CodRecorrido:clase.CodRecorrido ,
                CodVehiculo:clase.CodVehiculo ,
                FechaHoraIncioEjecutado:toLocalISOString(new Date(clase.FechaHoraInicioProgramado)),
                FechaHoraFinEjecutado:toLocalISOString(new Date(clase.FechaHoraFinProgramado)),
                codEmpresa:stateUser.codEmpresa 
            })
        });
        notify(response.content, response.isValid ? 'success' : 'error');
        
        if (response.isValid) {
            setClase(claseDefault);
            await listarClases();
            closeModalProgramacion()
        }
    }

    const editarValorClase = (key,value) =>{
        console.log("EDITAR VALOR CLASE -> ", key,value)
        setClase(clase => {
            return {
                ...clase,
                [key]: value
            }
        });
    }     
    return {
        clases,
        listarClases,
        guardarEditarClase,
        clase,
        editarValorClase,
        obtenerClase,
        spinner,
        isOpenModalProgramacion,
        openModalProgramacion,
        closeModalProgramacion,
    };
}