import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
registerLocale('es', es);





export const DatePickerABX = ({
    date,
    setDate,
    styles
}) => {

    const DateInput = forwardRef(({ value, onClick }, ref) => (
        <input
            onClick={onClick}
            ref={ref}
            className="border border-gray-300 text-center text-gray-900 h-[30px] rounded px-4 w-full text-l focus:outline-none focus:ring-1 focus:ring-blue-500"
            defaultValue={value}
            style={styles}
            readOnly
        />
    ));

    // Estilos de Material UI
    const materialUIStyles = {
        container: {
            display: 'inline-block',
            position: 'relative',
            width: '100%',
            '& .react-datepicker-wrapper': {
                width: '100%'
            },

        },
        input: {
            width: '100%',
            padding: '12px 14px',
            fontSize: '1rem',
            border: '1px solid #ced4da',
            borderRadius: '4px',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
        }
    };

    return (
        <div style={materialUIStyles.container}>
            <DatePicker
                locale="es"
                selected={date}
                onChange={date => setDate(date)}
                customInput={<DateInput />}
                dateFormat="dd/MM/yyyy"
            />
        </div>
    );
};
