import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch, Fetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlRoles = process.env.REACT_APP_PROMETHEUS_API + "/roles";

export const useRoles = () => {
    const { stateUser , signOut } = useContext(UserContext);
    const [ roles, setRoles ] = useState([]);
    
    useEffect(() => {
        listarRoles();
    }, []);

    const listarRoles = async () => {
        console.log("INGRESO A LA FUNCION LISTAR USAURIOS ")
        const response = await AuthFetch({
            url: urlRoles + '?'+ new URLSearchParams({
                cod_empresa : stateUser.codEmpresa 
            })
        });
        if (response.isValid) {
            console.log("RESPONSE ROLES -> ", response.content)
            setRoles(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    return {
        roles
    };
}