import React, { useEffect, useState, useContext } from 'react';
import { Modal } from '../../components/modal/Modal';
import { UserContext } from '../../../context/provider/UserProvider';
import { useModal } from '../../../hooks/useModal';
import { useTipoClases } from '../../../hooks/useTipoClases';
import { useMatricula } from '../../../hooks/useMatricula';
import { useUsuarios } from '../../../hooks/useUsuarios';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import { Cabecera } from '../../components/utils/Cabecera';
import { TextField, Button, InputAdornment, Avatar } from '@mui/material';
import { CustomAutocomplete } from '../../components/forms/CustomAutocomplete';
import { CustomTextField } from '../../components/forms/CustomTextField';

export const Matricula = () => {
  const { usuarios } = useUsuarios();
  const usuariosEstudiantes = usuarios.filter(u => u.CodRol == 4);

  const { tiposVehiculos } = useTiposVehiculos();
  const { matricula, guardarEditarMatricula, matriculas, editarValorMatricula, obtenerMatricula, isOpen, closeModal } = useMatricula();
  const { tipoClases, editarValorTipoClase } = useTipoClases();

  const formatearFecha = (fechaHoraRecibida) => {
    const fechaHora = new Date(fechaHoraRecibida);
    const nuevaFechaHoraFormateada = fechaHora.toLocaleString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Usa formato de 24 horas
    });
    return nuevaFechaHoraFormateada;
  };

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTipoClase, setSelectedTipoClase] = useState(null);
  const [filteredMatriculas, setFilteredMatriculas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredMatriculas(matriculas);
    } else {
      const filtered = matriculas?.filter(matricula =>
        ['NombreTipoClase', 'Estudiante', 'CantidadHoras', 'MontoPagado', 'UsuarioAtencion', 'ComentarioMatricula']
          .some(key =>
            matricula[key] && typeof matricula[key] === 'string' && matricula[key].toLowerCase().includes(searchTerm)
          ));
      setFilteredMatriculas(filtered);
    }
  }, [searchTerm, matriculas]);

  const filtros = [
    { nombre: 'Estudiante', label: 'Estudiante' },
    { nombre: 'UsuarioAtencion', label: 'Usuario Atención' },
    { nombre: 'NombreTipoClase', label: 'Tipo de Matrícula' },
  ];

  const handleTipoClaseChange = (event, value) => {
    editarValorMatricula("CodTipoClase", value.CodTipoClase);
    editarValorMatricula("CantidadHoras", value.CantidadHoras);
    editarValorMatricula("CodTipoVehiculo", value.CodTipoVehiculo);
    editarValorMatricula("Monto", value.Monto);
    setSelectedTipoClase(value);
  };

  const handleStudentChange = (event, value) => {
    console.log("handleStudentChange-> ", value);
    setSelectedStudent(value);
    editarValorMatricula("Estudiante", value?.Usuario || '');
  };

  const handleDecimalInputChange = (key, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      editarValorMatricula(key, value);
    }
  };

  const handleIntegerInputChange = (key, value) => {
    if (/^\d*$/.test(value)) {
      editarValorMatricula(key, value);
    }
  };

  return (
    <>
      <Cabecera
        titulo="Matriculas"
        onNuevo={() => obtenerMatricula()}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        data={matriculas}
        updateFilteredData={setFilteredMatriculas}
        searchFields={['NombreTipoClase', 'Estudiante', 'CantidadHoras', 'MontoPagado', 'UsuarioAtencion', 'ComentarioMatricula']}
        placeholder="Buscar en Matriculas..."
        nuevoTexto="Nueva matricula"
        filtros={filtros}
      />

      <div className="">
        <ul className="cards">
          {filteredMatriculas?.length > 0 ? filteredMatriculas?.map((matricula, i) => (
            <li className='cursor-pointer' key={i}>
              <div className="details">
                <span style={{ color: 'white' }}><i className='fa fa-calendar mr-2' />{formatearFecha(matricula.FechaMatricula)}</span>
                <span style={{ color: 'white' }}><i className='fa fa-address-card mr-2' />{matricula.NombreTipoClase}</span>
                <span style={{ color: 'white' }}><i className='fa fa-user mr-2' />{matricula.Estudiante}</span>
                <span style={{ color: 'white' }}><i className='fa fa-clock mr-2' />{matricula.CantidadHoras} Horas</span>
                <span style={{ color: 'white' }}><i className='fa fa-money mr-2' />S/{matricula.MontoPagado} de S/{matricula.Monto}</span>
                <span style={{ color: 'white' }}><i className='fa fa-user-circle-o mr-2' />{matricula.UsuarioAtencion}</span>
                <span style={{ color: 'white' }}><i className='fa fa-comments mr-2' />{matricula.ComentarioMatricula}</span>
                <span>
                  <Button onClick={() => { obtenerMatricula(matricula.CodMatricula); }} variant="contained" color="primary">
                    <i className="fas fa-edit"></i> Editar
                  </Button>
                </span>
              </div>
            </li>
          )) : (
            <div className='text-center' style={{ color: 'white' }}>No hay información para mostrar por el momento</div>
          )}
        </ul>
      </div>

      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        action={() => guardarEditarMatricula()}
        title={`${matricula.CodMatricula ? 'Detalle  Matricula' : 'Registrar Matricula'}`}
        width={'50%'}
      >
        <div className="lg:flex-col lg:max-h-[500px] lg:overflow-y-auto text-l flex justify-center items-start containerScroll bg-gray-900 text-white p-4 rounded-lg">
          <div className="w-full px-4 lg:flex-col lg:flex">
            <div className="flex flex-col justify-between w-full form-content">

              {/* Estudiante y Tipo de Clase en la misma fila */}
              <div className="lg:flex-col lg:w-full flex space-x-4 mb-4">
                <div className="w-2/3 lg:flex-col lg:w-full">
                  <CustomAutocomplete 
                    label="Estudiante"
                    value={selectedStudent}
                    onChange={handleStudentChange}
                    options={usuariosEstudiantes}
                    labelKey="Usuario"
                    valueKey="CodUsuario"
                    secondLabelKey="DNI"
                  />
                </div>
                <div className="w-1/3 lg:flex-col lg:w-full flex items-center justify-center">
                  {selectedStudent && (
                    <Avatar alt={selectedStudent.Nombres} src={selectedStudent.Foto} sx={{ width: 56, height: 56 }} />
                  )}
                </div>
              </div>

              {/* Tipo de Clase y Tipo de Vehículo en la misma fila */}
              <div className="flex space-x-4 mb-4 lg:flex-col lg:w-full">
                <div className="w-1/2 lg:flex-col lg:w-full">
                  <CustomAutocomplete
                    label="Tipo de Clase"
                    value={selectedTipoClase}
                    onChange={handleTipoClaseChange}
                    options={tipoClases}
                    labelKey="Nombre"
                    valueKey="CodTipoClase"
                  />
                </div>
                <div className="w-1/2 lg:flex-col lg:w-full">
                  <CustomAutocomplete
                    label="Tipo de Vehículo"
                    value={selectedTipoClase}
                    onChange={(event, newValue) => editarValorMatricula("CodTipoVehiculo", newValue?.CodTipoVehiculo || '')}
                    options={tiposVehiculos}
                    labelKey="NombreTipoVehiculo"
                    valueKey="CodTipoVehiculo"
                    disabled={false}
                  />
                </div>
              </div>

              {/* Cantidad de Horas y Monto Paquete en la misma fila */}
              <div className="flex space-x-4 mb-4">
                <div className="w-1/2">
                  <CustomTextField
                    label="Cantidad de Horas"
                    value={matricula.CantidadHoras}
                    onChange={(e) => handleDecimalInputChange("CantidadHoras", e.target.value)}
                  />
                </div>
                <div className="w-1/2">
                  <CustomTextField
                    label="Monto Paquete"
                    value={matricula.Monto}
                    onChange={(e) => handleDecimalInputChange("Monto", e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span style={{ color: '#ffffff' }}>S/.</span>
                        </InputAdornment>
                      ),
                      className: "bg-gray-700 text-white",
                      style: { color: '#ffffff' }
                    }}
                  />
                </div>
              </div>

              {/* Monto Pagado y Comentario */}
              <div className="mb-4">
                <CustomTextField
                  label="Monto Pagado"
                  value={matricula.MontoPagado}
                  onChange={(e) => handleDecimalInputChange("MontoPagado", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span style={{ color: '#ffffff' }}>S/.</span>
                      </InputAdornment>
                    ),
                    className: "bg-gray-700 text-white",
                    style: { color: '#ffffff' }
                  }}
                />
              </div>

              <div className="mb-4">
                <TextField
                  label="Comentario"
                  variant="filled"
                  value={matricula.ComentarioMatricula || ''}
                  onChange={(e) => editarValorMatricula("ComentarioMatricula", e.target.value)}
                  className="bg-gray-700 text-white rounded-t"
                  InputProps={{ className: "bg-gray-700 text-white", style: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                  fullWidth
                  multiline
                  rows={4}
                />
              </div>

            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};


            {/* <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarPersona(persona.codPersona)}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >

                <div className="flex gap-8 items-center flex-wrap justify-center">
                    <div className="w-[400px] px-4">
                        <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                    </div>
                </div>
                    
                
            </Modal>    */}

