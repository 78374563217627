import React, { useState,useEffect } from 'react';

const FloatingButton = ({onButtonClick}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <button
        onClick={onButtonClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`
          border bg-green-500 
          text-white  
          px-3 py-2
          rounded-full 
          cursor-pointer
          transition-transform transform 
          ${isHovered ? 
            ' text-xl  w-[120px]  ' :
            ' text-xl '}
        `}
      >
        <i className="fa fa-plus" />
        <span className=' text-xl lg:hidden  '> Nuevo</span>
      </button>
    </div>
  );
};

export default FloatingButton;
