import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch, Fetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlUsuarios = process.env.REACT_APP_PROMETHEUS_API + "/usuarios";

export const useUsuarios = () => {

    const { stateUser , signOut } = useContext(UserContext);
    const usuarioDefault = {
        CodUsuario: 0,
        Usuario: '',
        Clave: '',
        DNI: '',
        Nombres: '',
        ApellidoPaterno: '',
        ApellidoMaterno: '',
        FechaNacimiento: new Date(),
        Direccion: '',
        Celular: '',
        Email: '',
        Foto: 'https://cdn-icons-png.flaticon.com/256/1535/1535791.png',
        CodEmpresa: 0,
        Empresa: '',
        CodRol: 0,
        CodTipoDocumento: 1,
        BDEscuela: ''


    }
    const [ usuarios, setUsuarios ] = useState([]);
    const [ usuario, setUsuario ] = useState(usuarioDefault);
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    const [ isOpen, openModal, closeModal] = useModal();
    const [ fotoFile, setFotoFile ] = useState(null); 
    const [ fotoUrlUsuario, setFotoUrlUsuario ] = useState();
    
    const [ cargandoBusquedaDNI, setCargandoBusquedaDNI ] = useState()



    useEffect(() => {
        listarUsuarios();
    }, []);

    const listarUsuarios = async () => {
        console.log("INGRESO A LA FUNCION LISTAR USAURIOS ")
        const response = await AuthFetch({
            url: urlUsuarios + '?'+ new URLSearchParams({
                database  : stateUser.bdEscuela 

            })
        });
        if (response.isValid) {
            console.log("RESPONSE USUARIOS -> ", response.content)
            setUsuarios(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const guardarUsuarioFoto = async () => {
        if (fotoFile != null){

            const fotoData = new FormData();
            fotoData.append('archivo', fotoFile);
            const response = await Fetch({
                url:process.env.REACT_APP_PROMETHEUS_MINIO + '/api/Archivo/subirArchivo?' + new URLSearchParams({
                    NombreCarpeta:"usuarios",
                    NombreArchivo: `${usuario.DNI}`,
                }),
                headers:{
                    contentType: 'image/*'
                    // 'Content-Type':'image/*'
                },
                method:'POST',
                body : fotoData
            });
            if (response.isValid) {
                setFotoFile(null)
                usuario.Foto=`${process.env.REACT_APP_PROMETHEUS_MINIO}/api/Archivo/verImagen?NombreCarpeta=usuarios&NombreImagen=${usuario.DNI}`
                guardarEditarUsuario()
            }else{
                notify(response.exceptions[0].description, 'error');
            }
        }else{
            guardarEditarUsuario()
        }
    }
    


    const obtenerUsuario= async (uid) => {
        console.log("CODUSUARIO DEL OBTENER USUARIO -> ", uid)
        if (uid) {
            const response = await AuthFetch({
                url: urlUsuarios + '/usuario?'+ new URLSearchParams({
                    usuario  : uid,
                })
            });
            console.log("RESPONSE OBTENER USUARIO -> ", response)
            if (response.isValid) {
                setUsuario(response.content);
                setFotoUrlUsuario(response.content.Foto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setUsuario(usuarioDefault);
            setFotoUrlUsuario()

        }
        openModal();
    }
    
    const obtenerDatosIdentidad = async (dni) =>{
        setCargandoBusquedaDNI(true)
        if (dni) {
            const response = await AuthFetch({
                url:`https://identidadwebapi.abexacloud.com/api/Identidad/obtenerDni?dni=${dni}`
            });
            console.log("RESPONSE OBTENER USUARIO identidad -> ", response)

            if (response.isValid) {
                const fechaNac = response.content.fechaNacimiento; // "14/01/1987"
                const parts = fechaNac.split('/');
                const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

                // alert(response.content.fechaNacimiento)
                setUsuario({
                    ...usuario,
                    ApellidoPaterno: response.content.apellidoPaterno,
                    ApellidoMaterno: response.content.apellidoMaterno,
                    Nombres: response.content.nombres,
                    FechaNacimiento: formattedDate,
                    Direccion: response.content.direccion,
                });
                
            } else {
                notify(response.exceptions[0].description, 'error');
            }
        } else {
            setUsuario(usuarioDefault);
        }   
        setCargandoBusquedaDNI(false)
    }
    
    
    const guardarEditarUsuario = async () => {

        console.log("USUARIOA A REGISTRARSE -> ", usuario)
        if(!usuario.CodRol){
            notify("Debe seleccionar un Rol","error")
            return
        }

        if(!usuario.Usuario){
            notify("Debe ingresar un Usuario","error")
            return
        }


        const esGuardar = usuario.CodUsuario == 0? true : false;
        const response = await AuthFetch({
            url: urlUsuarios ,
            method: esGuardar ? 'POST' : 'PUT',
            body : JSON.stringify({
                // ...(!esGuardar && { CodUsuario: usuario.CodUsuario }),
                CodUsuario: usuario.CodUsuario,
                Usuario: usuario.Usuario,
                Clave: usuario.Clave,
                DNI: usuario.DNI,
                Nombres: usuario.Nombres,
                ApellidoPaterno: usuario.ApellidoPaterno,
                ApellidoMaterno: usuario.ApellidoMaterno,
                FechaNacimiento: usuario?.FechaNacimiento,
                Direccion: usuario.Direccion,
                Celular: usuario.Celular,
                Email: usuario.Email,
                Foto: usuario.Foto,
                CodEmpresa:stateUser.codEmpresa,
                CodRol:usuario.CodRol,
                Empresa: stateUser.codEmpresa + '',
                BDEscuela: stateUser.bdEscuela

                
            })
        });

        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setUsuario(usuarioDefault);
            await listarUsuarios();
            closeModal()
        }
    }

    const editarValorUsuario = (key,value) =>{
        console.log("EDITAR VALOR USUARIO -> ", key,value)
        setUsuario(usuario => {
            return {
                ...usuario,
                [key]: value
            }
        });
    }     
    return {
        usuarios,
        listarUsuarios,
        guardarEditarUsuario,
        usuario,
        editarValorUsuario,
        obtenerUsuario,
        spinner,
        isOpen,
        closeModal,
        openModal,
        obtenerDatosIdentidad,
        guardarUsuarioFoto,
        fotoUrlUsuario,
        setFotoFile,
        setFotoUrlUsuario,
        cargandoBusquedaDNI

    };
}