import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from '../../components/modal/Modal';
import { UserContext } from '../../../context/provider/UserProvider';
import FloatingButton from '../../components/FloatingButton';
import { useModal } from '../../../hooks/useModal';
import { useVehiculos } from '../../../hooks/useVehiculos';
import autoDefault from '../../../assets/images/autoDefault.jpg'
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import { Cabecera } from '../../components/utils/Cabecera';


export const Vehiculos = () => {
    const [selectedFile, setSelectedFile] = useState()
    const inputFoto = useRef();
    const [ foto, setFoto ] = useState()
    const [isOpenModalUnidad, openModalUnidad, closeModalUnidad ] = useModal()
    const classNameInput = "lg:text-xs caret-black text-gray-800 h-[30px] rounded px-4 w-[220px] lg:w-full text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   

    const { 
            tiposVehiculos
        } = useTiposVehiculos()
    

    const fotoDefault = autoDefault

    const [registrarEditar,setRegistrarEditar] = useState(true)

    const {
        vehiculos,
        listarVehiculos,
        guardarEditarVehiculo,
        vehiculo, 
        editarValorVehiculo, 
        obtenerVehiculo, 
        isOpenModalRegistrar, 
        closeModalRegistrar,
        closeModal, 
        setFotoFile,
        eliminarPersona, 
        isOpenModalEliminar, 
        openModalEliminar,
        closeModalEliminar, 
        vehiculoEliminar,
        fotoUrlVehiculo,
        setFotoUrlVehiculo, 
        guardarVehiculoFoto
    }= useVehiculos()


    const [filteredVehiculos, setFilteredVehiculos] = useState(vehiculos);
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (filteredResults) => {
        setFilteredVehiculos(filteredResults);
    };


    useEffect(() => {
        if (!selectedFile) {
            setFotoUrlVehiculo(fotoDefault)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setFotoUrlVehiculo(objectUrl || fotoDefault)
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(fotoDefault)
            resizeAndSetImage(e.target.files[0])
            return
        }
        setSelectedFile(e.target.files[0])
        setFotoFile(e.target.files[0])
    
    }

    // const onSelectFile = e => {
    //     if (!e.target.files || e.target.files.length === 0) {
    //         // Si no se seleccionó un archivo, regresa al estado predeterminado
    //         setSelectedFile(undefined);
    //         setFotoUrlVehiculo(fotoDefault);
    //         return;
    //     }
    //     const file = e.target.files[0];
    //     if (!file.type.startsWith('image/')) {
    //         // Si el archivo seleccionado no es una imagen
    //         console.log("El archivo seleccionado no es una imagen.");
    //         return;
    //     }
    //     // Si es una imagen, primero actualizamos el estado del archivo seleccionado
    //     setSelectedFile(file);
    //     // Y luego procedemos a redimensionar y actualizar la imagen
    //     // resizeAndSetImage(file);
    // }
    
    const base64ToBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64.split(',')[1]);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
    
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        return new Blob(byteArrays, {type: mimeType});
    };



    const resizeAndSetImage = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
    
            img.onload = () => {
                const canvas = document.createElement('canvas'); // Asegúrate de que esta línea está dentro del alcance donde necesitas usar canvas
                const ctx = canvas.getContext('2d');
                let width = img.width;
                let height = img.height;
                const maxWidth = 200;
                const maxHeight = 200;
    
                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }
    
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
    
                const resizedDataURL = canvas.toDataURL('image/jpeg', 0.5);
                // Continúa con el proceso de convertir a Blob y luego a File como se mostró anteriormente
                const imageBlob = base64ToBlob(resizedDataURL, 'image/jpeg');
                const imageFile = new File([imageBlob], "resized_image.jpeg", {type: 'image/jpeg'});
    
                // Actualiza el estado con el objeto File y/o la URL para visualización
                setFotoFile(imageFile); // Asume que esta función actualiza el estado con el archivo
                const objectURL = URL.createObjectURL(imageFile);
                setFotoUrlVehiculo(objectURL); // Asume que esta función actualiza el estado con la URL para visualización
            };
        };
        reader.readAsDataURL(file); // Esta línea inicia la lectura del archivo, asegurando que el proceso comience correctamente.
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };
    const [tab, setTab ] = useState(1)
    console.log("darkMode Vehiculos->")

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredVehiculos(vehiculos);
        } else {
            const filtered = vehiculos.filter(vehiculo =>
                ['Placa', 'Marca', 'Modelo', 'Categoria', 'Combustible', 'Direccion', 'Usuario','NombreTipoVehiculo']
                .some(key => 
                    vehiculo[key] && typeof vehiculo[key] === 'string' && vehiculo[key].toLowerCase().includes(searchTerm)
                )                
            );
            console.log("filtered -> ", filtered)
            setFilteredVehiculos(filtered);
        }
    }, [searchTerm, vehiculos]);

    const filtros = [
        { nombre: 'Combustible', label: 'Combustible' },
        { nombre: 'Marca', label: 'Marca' },
        // Puedes agregar más filtros aquí
    ];

    return (
        <>

{/* <SearchTable data={vehiculos} onSearch={()=>handleSearch()} /> */}


<Cabecera
                titulo="Vehículos"
                onNuevo={() => obtenerVehiculo()}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                data={vehiculos}
                updateFilteredData={setFilteredVehiculos}
                searchFields={['Placa', 'Marca', 'Modelo', 'Categoria', 'Combustible', 'Direccion', 'Usuario','NombreTipoVehiculo']}
                placeholder="Buscar en vehículos..."
                nuevoTexto="Nuevo vehículo" // Texto del botón "Nuevo"
                filtros={filtros} // Filtros para el modal
            />

<div className="containerScroll mb-10 mt-10 relative top-0 bottom-0 left-0 right-0 contenedosr-tabla">

    {/* <div className="flex flex-wrap justify-center gap-4"> */}
    {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"> */}
    {/* <div className="flex flex-wrap lg:grid-cols-1 lg:grid justify-center lg:gap-2 gap-4"> */}


    {/* <div key={producto.id} className='border p-4 rounded-xl border-red-500 border bg-white shadow-md'> */}
    <div className="">
        <ul className="cards">
                {   
                    filteredVehiculos?.length > 0 
                    ? filteredVehiculos?.map((vehiculo, i) => {
                        return (

                            <li className='cursor-pointer'>
                                <img src={vehiculo.Foto} className='rounded' />
                                <div className="details ">
                                    <span><i className='fas fa-car  mr-2'/>{vehiculo.Marca} {vehiculo.Modelo} {vehiculo.Año}</span>
                                    <span><i className='fa fa-imdb  mr-2'/>{vehiculo.Placa}</span>
                                    <span><i className='fas fa-car-side  mr-2'/>{vehiculo.NombreTipoVehiculo}</span>
                                    <span><i className='fas fa-gas-pump  mr-2'/>{vehiculo.Combustible}</span>

                                    <div className='flex'>
                                        <button onClick={()=>obtenerVehiculo(vehiculo.CodVehiculo)} className="text-white bg-blue-500 hover:bg-blue-600 text-sm px-3 py-1 rounded mr-2">
                                            <i className="fas fa-edit mr-1"></i> Editar
                                        </button>
                                        <button onClick={openModalEliminar} className="text-white bg-red-500 hover:bg-red-800 text-sm px-3 py-1 rounded">
                                            <i className="fas fa-trash mr-1"></i> Eliminar
                                        </button>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                    : <div  className="text-center">No hay información que mostrar con los parámetros seleccionados.</div>
                }
            </ul>

    </div>
</div>
    <FloatingButton onButtonClick={()=>obtenerVehiculo()}/>


            <Modal 
                isOpen={isOpenModalRegistrar} closeModal={closeModalRegistrar}
                action={()=>guardarVehiculoFoto()}
                title={`${vehiculo.CodVehiculo == 0 ? 'Registrar Unidad' : 'Editar Unidad'}`}
            >
                    <div className="flex containerScroll lg:max-h-[300px] lg:overflow-y-none  items-center flex-wrap justify-center">

                    <div className="text-center relative inline-block">
                                
                                        
                        <input type="file" ref={inputFoto} style={{ display: 'none' }} accept="image/*" onChange={onSelectFile} />
                            <img 
                                className='hover:ring-2 hover:ring-[#E2504C] rounded w-[250px] h-[250px] mb-1 lg:w-[100px] lg:h-[100px] cursor-pointer'
                                src={fotoUrlVehiculo || fotoDefault} 
                                onClick={() => inputFoto.current.click()}
                                alt=''  
                            />
                            <div className="text-center relative inline-block">
                                <input
                                    type="file"
                                    ref={inputFoto}
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={onSelectFile}
                                />
                                {/* <img
                                    className='hover:ring-2 hover:ring-[#E2504C] rounded w-[250px] h-[250px] mb-1 lg:w-[200px] lg:h-[200px] cursor-pointer'
                                    src={foto || fotoDefault}
                                    onClick={() => inputFoto.current.click()}
                                    alt=''
                                /> */}
                            </div>
                    </div>

                    <div className="w-[400px]  lg:w-[100%] h-[400px] lg:h-[180px] mt-0">
                        <div className="flex flex-col w-full form-content">
                            <div className="flex bg-[#112940] text-gray-100 gap-4 mb-1 px-2  rounded-t-lg">
                                <div className={`lg:text-xs text-center text-lg cursor-pointer ${tab === 1 ? 'text-blue-200 border-b-2 border-blue-500' : 'cursor-pointer text-gray-100 hover:text-blue-500 focus:text-blue-500 focus:outline-none transition-all duration-300' }`} onClick={() => setTab(1)}>
                                    General
                                </div>
                                <div className={`lg:text-xs text-center text-lg cursor-pointer ${tab === 2 ? 'text-blue-200 border-b-2 border-blue-500' : 'cursor-pointer text-gray-100 hover:text-blue-500 focus:text-blue-500 focus:outline-none transition-all duration-300'}`} onClick={() => setTab(2)}>
                                    Datos Técnicos
                                </div>
                                <div className={`lg:text-xs text-lg text-center cursor-pointer ${tab === 3 ? 'text-blue-200 border-b-2 border-blue-500' : 'cursor-pointer text-gray-100 hover:text-blue-500 focus:text-blue-500 focus:outline-none transition-all duration-300'}`} onClick={() => setTab(3)}>
                                    Caracteristicas
                                </div>
                                
                                </div>
                            </div> 
                            {/* /DATOS GENERALES */}
                                <div className={`${tab===1? ' visible ' : ' hidden ' } px-4 border mt-[-4px] pt-4 w-full h-full containerScroll lg:max-h-[300px] lg:overflow-y-auto`}>
                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span className=''>Placa: </span>
                                        <input 
                                            defaultValue={vehiculo.Placa}
                                            className={`${classNameInput}'`}
                                            onChange={(e)=>editarValorVehiculo("Placa",e.target.value)}
                                        />
                                        <button 
                                            onClick={()=>console.log('')}
                                            className={`absolute right-10 hover:bg-green-500 bg-green-600 p-[3px] rounded px-2`}>
                                                <i className='fa fa-search text-white'></i>
                                        </button>
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <label>Transmisión: </label>
                                        <select 
                                            className={`${classNameInput}`}
                                            defaultValue={vehiculo.CodTipoVehiculo} 
                                            value={vehiculo.CodTipoVehiculo} 
                                            onChange={(e) => {editarValorVehiculo("CodTipoVehiculo", e.target.value)}} 
                                        >
                                            <option className='' value={0}>-- Seleccione --</option>
                                            {
                                                tiposVehiculos?.map(r => {
                                                    return <option key={r.CodTipoVehiculo} value={r.CodTipoVehiculo}>{r.NombreTipoVehiculo}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Marca: </span>
                                        <input 
                                            defaultValue={vehiculo.Marca}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Marca",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Año: </span>
                                        <input 
                                            defaultValue={vehiculo.Año}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Año",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Tarj. Propiedad: </span>
                                        <input 
                                            defaultValue={vehiculo.TarjetaPropiedad}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("TarjetaPropiedad",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>NMotor: </span>
                                        <input 
                                            defaultValue={vehiculo.NMotor}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("NMotor",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Categoria: </span>
                                        <input 
                                            defaultValue={vehiculo.Categoria}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Categoria",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Modelo: </span>
                                        <input 
                                            defaultValue={vehiculo.Modelo}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Modelo",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Carroceria: </span>
                                        <input 
                                            defaultValue={vehiculo.Carroceria}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Carroceria",e.target.value)}
                                        />
                                    </div>
                                </div>

                            {/* DATOS TECNICOS */}
                                <div className={` ${tab===2?'visible':'hidden'} px-4 border mt-[-4px] pt-4 w-full h-full containerScroll lg:max-h-[300px] lg:overflow-y-auto`}>
                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Combustible: </span>
                                        <input 
                                            defaultValue={vehiculo.Combustible}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Combustible",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Serie: </span>
                                        <input 
                                            defaultValue={vehiculo.Serie}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Serie",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Color: </span>
                                        <input 
                                            defaultValue={vehiculo.Color}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Color",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Peso Bruto: </span>
                                        <input 
                                            defaultValue={vehiculo.PesoBruto}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("PesoBruto",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Peso Seco:</span>
                                        <input 
                                            defaultValue={vehiculo.PesoSeco}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("PesoSeco",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Ancho: </span>
                                        <input 
                                            defaultValue={vehiculo.Ancho}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Ancho",e.target.value)}
                                        />
                                    </div>
                                </div>
                            
                                {/* CARACTERISTICAS */}
                                <div className={`${tab==3? 'visible' : 'hidden' } px-4 border mt-[-4px] pt-4 w-full h-full containerScroll lg:max-h-[300px] lg:overflow-y-auto`}>
                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Asientos: </span>
                                        <input 
                                            defaultValue={vehiculo.Asientos}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Asientos",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Cilindros: </span>
                                        <input 
                                            defaultValue={vehiculo.Cilindros}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Cilindros",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Puertas: </span>
                                        <input 
                                            defaultValue={vehiculo.Puertas}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Puertas",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>CargaUtil: </span>
                                        <input 
                                            defaultValue={vehiculo.CargaUtil}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("CargaUtil",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Longitud: </span>
                                        <input 
                                            defaultValue={vehiculo.Longitud}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Longitud",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Altura: </span>
                                        <input 
                                            defaultValue={vehiculo.Altura}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Altura",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Pasajeros: </span>
                                        <input 
                                            defaultValue={vehiculo.Pasajeros}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Pasajeros",e.target.value)}
                                        />
                                    </div>

                                    <div className='lg:flex-col mb-2 w-full flex justify-between '>
                                        <span>Ejes: </span>
                                        <input 
                                            defaultValue={vehiculo.Ejes}
                                            className={`${classNameInput}`}
                                            onChange={(e)=>editarValorVehiculo("Ejes",e.target.value)}
                                        />
                                    </div>


                                </div>
                    </div>
                    
                </div>
            </Modal>

            <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={{}}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                        </div>
                    </div>
                    
                
            </Modal>   


        </>
    )


}