import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from '../../components/modal/Modal';
import { UserContext } from '../../../context/provider/UserProvider';
import { useTipoClases } from '../../../hooks/useTipoClases';
import { useTiposVehiculos } from '../../../hooks/useTiposVehiculos';
import { Cabecera } from '../../components/utils/Cabecera';
import { CustomSelect } from '../../components/forms/CustomSelect';
import { CustomTextField } from '../../components/forms/CustomTextField';
import { TextField, Button, InputAdornment, Avatar } from '@mui/material';

export const TipoClases = () => {


    const {
        tipoClase,
        listarTipoClases,
        guardarEditarTipoClase,
        tipoClases,
        editarValorTipoClase,
        obtenerTipoClase,
        spinner,
        isOpen,
        closeModal,
        openModal
    } = useTipoClases()

    const {
        tiposVehiculos
        } = useTiposVehiculos()



    const classNameInput = "text-gray-800 h-[30px] rounded px-4 w-full  text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const {stateUser}  = useContext(UserContext)


    const [filteredTiposClase, setFilteredTiposClase] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredTiposClase(tipoClases);
        } else {

            const filtered = tipoClases.filter(tipoClase =>
                [ 'Descripcion', 'Nombre', 'NombreTipoVehiculo','Monto']
                .some(key => 
                    tipoClase[key] && typeof tipoClase[key] === 'string' && tipoClase[key].toLowerCase().includes(searchTerm)
                )                
            );
            setFilteredTiposClase(filtered);
        }
    }, [searchTerm, tipoClases]);
    
    const filtros = [
        { nombre: 'Nombre', label: 'Nombre' },
    ];

    
  return (
            
    <>


                
      <Cabecera
          titulo="Tipo de clases"
          onNuevo={() => obtenerTipoClase()}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          data={tipoClases}
          updateFilteredData={setFilteredTiposClase}
          searchFields={[ 'Descripcion', 'Nombre', 'NombreTipoVehiculo','Monto']}
          placeholder="Buscar en tipos de clases..."
          nuevoTexto="Nuevo" 
          filtros={filtros}
      />        

      <div className="">
          <ul className="cards">
              {
              filteredTiposClase.length > 0 
              ? filteredTiposClase.map((t, i)=> {
                  return (
                      <li className='cursor-pointer'>
                          <div className="details ">
                              <span ><i className='fa fa-address-card mr-2'/>{t.Descripcion}</span>
                              <span ><i className='fa fa-car mr-2'/>{t.NombreTipoVehiculo}</span>
                              <span ><i className='fa fa-clock mr-2'/>{t.CantidadHoras}H</span>
                              <span ><i className='fa fa-money mr-2'/>S/. {t.Monto}</span>
                              <span >
                                  <button onClick={() => {obtenerTipoClase(t.CodTipoClase);}} className="text-white bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded">
                                      <i className="fas fa-edit"></i>editar
                                  </button>
                                  <button  onClick={()=> obtenerTipoClase(t.CodTipoClase)} className="text-white bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3">
                                      <i className="fas fa-trash"></i>Eliminar
                                  </button>
                              </span>
                          
                              
                          </div>
                      </li>
                  )
                  })
                  : <div className='text-center'>No hay información para mostrar por el momento</div>
              }
          </ul>
      </div>


      <Modal 
        isOpen={isOpen} closeModal={closeModal}
        action={() => guardarEditarTipoClase()}
        title={`${tipoClase ? 'Nuevo' : 'Editar'}`}
        width={'400px'}
      >
                
      
        
        <div className="w-full lg:flex-col lg:max-h-[500px] lg:overflow-y-auto text-l flex justify-center items-start containerScroll  text-white p-4 rounded-lg ">
          
          <div className=" lg:flex-col w-full">
          
            <div className=" mb-4">
              <CustomTextField
                label="Nombre"
                value={tipoClase.Nombre}
                onChange={(e) => editarValorTipoClase("Nombre", e.target.value)}
              />
            </div>
            <div className=" mb-4">
              <CustomSelect
                label="Tipo de vehículo"
                value={tipoClase.CodTipoVehiculo}
                onChange={(e) => editarValorTipoClase("CodTipoVehiculo", e.target.value)}
                options={tiposVehiculos.map(tv => ({
                    value: tv.CodTipoVehiculo,
                    label: tv.NombreTipoVehiculo
                }))}
              />
            </div>
            <div className=" mb-4">
              <CustomTextField
                label="Monto"
                value={tipoClase.Monto}
                onChange={(e) => editarValorTipoClase("Monto", e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span style={{ color: '#ffffff' }}>S/.</span>
                    </InputAdornment>
                  ),
                  className: "bg-gray-700 text-white",
                  style: { color: '#ffffff' }
                }}
              />                
            </div>
            <div className=" mb-4">
              <CustomTextField
                label="Horas"
                value={tipoClase.CantidadHoras}
                onChange={(e) => editarValorTipoClase("CantidadHoras", e.target.value)}
              />
            </div>
            <div className=" mb-4">
              <TextField
                label="Descripcion"
                variant="filled"
                value={tipoClase.Descripcion}
                onChange={(e) => editarValorTipoClase("Descripcion", e.target.value)}
                className="bg-gray-700 text-white rounded-t"
                InputProps={{ className: "bg-gray-700 text-white", style: { color: 'white' } }}
                InputLabelProps={{ style: { color: 'white' } }}
                fullWidth
                multiline
                rows={4}
              />
            </div>
          </div>
        </div>
      </Modal>

            {/* <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarPersona(persona.codPersona)}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                        </div>
                    </div>
                    
                
            </Modal>    */}


        </>
    )


}