// components/CustomImage.js
import React from 'react';

export const CustomImage = ({ imageUrl, onImageChange }) => {
    const inputFoto = React.useRef();

    const handleImageClick = () => {
        if (inputFoto.current) {
            inputFoto.current.click(); // Esto abrirá el selector de archivos
        }
    };

    return (
        <div className="image-container">
            <input
                id="file-input"
                type="file"
                ref={inputFoto}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={onImageChange} // Esto manejará el cambio de archivo
            />
            <img
                src={imageUrl}
                onClick={handleImageClick} // Esto manejará el clic en la imagen
                alt="Imagen"
            />
            <div className="overlay" onClick={handleImageClick}>
                <i className="fa fa-camera"></i>
            </div>
            <div className="edit-icon" onClick={handleImageClick}>
                <i className="fa fa-pencil-alt"></i> {/* Icono de lapicito */}
            </div>
        </div>
    );
};
