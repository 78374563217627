import { useState, useEffect, useContext } from 'react';
import { notify } from '../../utils/utils';
import { useModal } from '../.././hooks/useModal';
import fotoDefault from '../../../src/assets/images/usuarioDefault.jpg';
import { AuthFetch } from '../../services/api';
import { UserContext } from '../../context/provider/UserProvider';

const personaDefault = {
    codPersona: 0,
    nomPersona: '',
    apePatPersona: '',
    apeMatPersona: '',
    telefonoPersona: '',
    codDocumentoTipo: 1,
    nroDocumento: '',
    codEntidad: 0,
    direccion: '',

}

const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const usePersonas = () => {
    const [ personas, setPersonas ] = useState([]);
    const [ persona, setPersona ] = useState(personaDefault);
    const [ isOpenModal, openModal, closeModal ] = useModal();
    const [ isOpenModalEliminar, openModalEliminar, closeModalEliminar ] = useModal();

    const [ fotoFile, setFotoFile ] = useState(null); 
    const [ fotoUrlPersona, setFotoUrlPersona ] = useState(fotoDefault);

    const {stateUser} = useContext(UserContext)
    
    const cargarDatosPersona = async (DNI) => {
        if (DNI.length === 8 ){
            const response = await AuthFetch({
                url: `${urlBasePlamin}/Personas/obtenerDatosDni?dni=${DNI}`
            });
            if (response.isValid) {
                notify('Se encontró información','info')
                setPersona({
                    codPersona: 0,
                    nomPersona: response.content.nombres,
                    apePatPersona: response.content.apellidoPaterno,
                    apeMatPersona: response.content.apellidoMaterno,
                    telefonoPersona: persona.telefonoPersona,
                    codDocumentoTipo: 1,
                    nroDocumento: response.content.dni,
                    // direccion:
                    // fechaNacimiento:
                })
            } else {
                notify('No se encontró información','info')
                setPersona(personaDefault)
            }
        }else{
        setPersona(personaDefault)
        }       
    }

    useEffect(() => {
        listarPersonas();
    }, []);

    const listarPersonas = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Personas?codEntidad=' + (stateUser.codUsuario == 1 ? 0 : stateUser.codEntidad),
        });

        if (response.isValid) {
            setPersonas(response.content);
        } else {
            notify(response.content, 'error');
        }
    }

    const obtenerPersona = async (codPersona) => {
        if (codPersona) {
            const response = await AuthFetch({
                url: urlBasePlamin + '/Personas/' + codPersona
            });

            if (response.isValid) {
                setPersona(response.content);
                verFotoUrlPersona(response.content.nombreCarpetaFoto, response.content.nombreFoto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setPersona(personaDefault);
            setFotoUrlPersona(fotoDefault)
        }
        openModal();
    }

    const personaEliminar = async (codPersona) =>{
        if (codPersona) {
            const response = await AuthFetch({
                url: urlBasePlamin + '/Personas/' + codPersona
            });

            if (response.isValid) {
                setPersona(response.content);
                verFotoUrlPersona(response.content.nombreCarpetaFoto, response.content.nombreFoto)
            } else {
                notify(response.content, 'error');
            }
        } else {
            setPersona(personaDefault);
            setFotoUrlPersona(fotoDefault)
        }
        openModalEliminar();

    }

    const eliminarPersona = async (codPersona)=>{
        const response = await AuthFetch({
            url: urlBasePlamin + '/Personas/eliminarPersona?' + new URLSearchParams({
                CodPersona: codPersona
            }),
            method:'PUT'
        })
        notify(response.content, response.isValid? 'success': 'error' )
        if (response.isValid) {
            listarPersonas()
        }
        closeModalEliminar()
    }
    
    const guardarEditarPersona = async () => {
        const fotoData = new FormData();
        fotoData.append('foto', fotoFile);
        // if (fotoFile === null && persona.codPersona === 0){
        //         notify('Tiene que seleccionar una Imagen','error')
        //         return;
        // }else 
        if(fotoFile !== null && fotoFile.size > 1049456){
                notify('El tamaño máximo de la imagen debe ser de 1MB ','error')
                return;
        }else{
                const esGuardar = persona.codPersona <= 0;
                const response = await AuthFetch({
                    url: urlBasePlamin + '/Personas?' + new URLSearchParams({
                        ...(!esGuardar && { codPersona: persona.codPersona }),
                        NomPersona:persona.nomPersona,
                        ApePaterno: persona.apePatPersona,
                        ApeMaterno:persona.apeMatPersona,
                        Telefono:persona.telefonoPersona,
                        CodDocumento:persona.codDocumentoTipo,
                        NumeroDocumento:persona.nroDocumento,
                        UsuarioAccion: stateUser.codUsuario,
                        NombreFoto:persona.nroDocumento,
                        CodEntidad: stateUser.codUsuarioTipo == 1 ? persona.codEntidad : stateUser.codEntidad       //Agregar en la api
                    }),
                    headers:{
                        contentType: 'image/*'
                    },
                    method: esGuardar ? 'POST' : 'PUT',
                    body : fotoData
                });
                notify(response.content, response.isValid ? 'success' : 'error');
                if (response.isValid) {
                    setPersona(personaDefault);
                    await listarPersonas();
                    closeModal()
                }
        }
    }



    const editarValorPersona = (key, value) => {
        setPersona(persona => {
            return {
                ...persona,
                [key]: value
            }
        });
    }

    const verFotoUrlPersona = (nomCarpeta,nombreFoto) => {
        if (nombreFoto === ''){
            setFotoUrlPersona(fotoDefault); 
        }else {
            setFotoUrlPersona(url => urlBasePlamin + `/Minio?nombreCarpetaFoto=${nomCarpeta}&nombreFoto=${nombreFoto}`);
        }
    }

    return {personas,
            listarPersonas,
            guardarEditarPersona,
            persona, 
            editarValorPersona, 
            obtenerPersona, 
            isOpenModal, 
            closeModal, 
            cargarDatosPersona,
            fotoUrlPersona,
            setFotoFile,
            setFotoUrlPersona,
            eliminarPersona, 
            isOpenModalEliminar, 
            closeModalEliminar, 
            personaEliminar }
}