import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlMatricula = process.env.REACT_APP_PROMETHEUS_API + "/matricula";

export const useMatricula = () => {
    const { stateUser , signOut } = useContext(UserContext);
    const matriculaDefault = {
        CodMatricula: 0,
        Estudiante: '',
        CodTipoClase: 0,
        CantidadHoras: 0,
        CantidadHorasRestantes: 0,
        FechaMatricula: new Date(),
        MontoPagado: 0,
        ComentarioMatricula: '',
        UsuarioAtencion:'' ,
        Monto: 0,
        BDEscuela : ''
    }
    const [ matriculas, setMatriculas ] = useState([]);
    const [ matricula, setMatricula ] = useState(matriculaDefault);
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    const [ isOpen, openModal, closeModal] = useModal();



    useEffect(() => {
        listarMatriculas();
    }, []);

    const listarMatriculas = async () => {
        const response = await AuthFetch({
            url: urlMatricula + '?'+ new URLSearchParams({
              database  : stateUser.bdEscuela,
            })
        });
        console.log("RESPONSE listarMatriculas ->", response.content)
        if (response.isValid) {
            console.log("RESPONSE MATRICULAS -> ", response.content)
            setMatriculas(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const obtenerMatricula= async (CodMatricula) => {
        if (CodMatricula) {
            const response = await AuthFetch({
                url: urlMatricula + '/obtenerMatricula?'+ new URLSearchParams({
                    cod_Matricula  : CodMatricula,
                    database  : stateUser.bdEscuela,
                })
            });
            console.log("RESPONSE OBTENER MATRICULA -> ", response)
            if (response.isValid) {
                setMatricula(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setMatricula(matriculaDefault);
        }
        openModal();
    }

    const fechaHoraActual = new Date();
    const desfaseHorarioEnMilisegundos = fechaHoraActual.getTimezoneOffset() * 60000; // Desfase en milisegundos
    const fechaHoraLocalISO = new Date(fechaHoraActual.getTime() - desfaseHorarioEnMilisegundos).toISOString();
    
    const guardarEditarMatricula = async () => {
        console.log("stateUser.Usuario -> ", stateUser )
        const esGuardar = matricula.CodMatricula == 0;
        const response = await AuthFetch({
            url: urlMatricula ,
            method: esGuardar ? 'POST' : 'PUT',
            body : JSON.stringify({
                ...(!esGuardar && { CodMatricula: matricula.CodMatricula }),
                Estudiante: matricula.Estudiante,
                CodTipoClase: matricula.CodTipoClase ,
                CantidadHoras:matricula.CantidadHoras,
                CantidadHorasRestantes: matricula.CantidadHoras,
                FechaMatricula:fechaHoraLocalISO.slice(0, 19).replace('T', ' '),
                MontoPagado:matricula.MontoPagado ,
                ComentarioMatricula:matricula.ComentarioMatricula ,
                UsuarioAtencion: stateUser.nomUsuario || '' ,
                Monto:matricula.Monto,
                BDEscuela  : stateUser.bdEscuela,
            })
        });
        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setMatricula(matriculaDefault);
            await listarMatriculas();
            closeModal()
        }
    }

    const editarValorMatricula= (key,value) =>{
        console.log("EDITAR VALOR MATRICULA  -> ", key,value)
        setMatricula(matricula => {
            return {
                ...matricula,
                [key]: value
            }
        });
    }     
    return {
        matricula,
        listarMatriculas,
        guardarEditarMatricula,
        matriculas,
        editarValorMatricula,
        obtenerMatricula,
        spinner,
        isOpen,
        closeModal,
        openModal
    };
}