import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from '../../components/modal/Modal';
import { usePersonas } from '../../../hooks/personas/usePersonas';
import { soloCelular, soloDNI } from '../../../utils/utils';
import { UserContext } from '../../../context/provider/UserProvider';
import { useModal } from '../../../hooks/useModal';
import { useRoles } from '../../../hooks/useRoles';
import { Cabecera } from '../../components/utils/Cabecera';


export const Roles = () => {
    const { personas, listarPersonas, guardarEditarPersona, persona, editarValorPersona, obtenerPersona, isOpenModal, closeModal , cargarDatosPersona, fotoUrlPersona, setFotoFile,setFotoUrlPersona,eliminarPersona,isOpenModalEliminar,closeModalEliminar,personaEliminar} = usePersonas();
    const [selectedFile, setSelectedFile] = useState()

    const [ isOpenModalRol, openModalRol, closeModalRol ] = useModal()

    const classNameInput = "text-gray-800 h-[30px] rounded px-4 w-full  text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const {stateUser}  = useContext(UserContext)
    const {roles} = useRoles()
    const [registrarEditar,setRegistrarEditar] = useState(true)


    const [filteredRoles, setFilteredRoles] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };
    useEffect(() => {
        if (searchTerm === "") {
            setFilteredRoles(roles);
        } else {
            const filtered = roles.filter(rol =>
                ['Nombres', 'ApellidoPaterno', 'ApellidoMaterno', 'NombreRol', 'Celular', 'Direccion', 'Usuario']
                    .some(key => rol[key].toLowerCase().includes(searchTerm))
            );
            setFilteredRoles(filtered);
        }
    }, [searchTerm, roles]);
    
    const filtros = [
        { nombre: 'NombreRol', label: 'Roles' },
        // Puedes agregar más filtros aquí
    ];

    

    return (
        <>
            <Cabecera
                titulo="Roles"
                onNuevo={() => openModalRol()}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                data={roles}
                updateFilteredData={setFilteredRoles}
                searchFields={['Nombres', 'ApellidoPaterno', 'ApellidoMaterno', 'NombreRol', 'Celular', 'Direccion', 'Usuario']}
                placeholder="Buscar en Roles..."
                nuevoTexto="Nuevo Rol" 
                filtros={filtros}
            />        
            <div className="">
                <ul className="cards">
                    {
                        filteredRoles.length > 0 ? filteredRoles.map((r, i) => {
                            return (
                                <li className='cursor-pointer'>
                                    <div className="details h-[150px] w-[200px]">
                                        <span><i className='fa fa-address-card mr-2' />{r.NombreRol}</span>
                                        <span><i className='fa fa-comments mr-2' />{r.DescripcionRol}</span>
                                        <span>
                                            <button onClick={()  => { console.log("CLICK") }} className="text-white bg-blue-500 hover:bg-blue-600 text-[14px] px-[8px] py-1 rounded">
                                                <i className="fas fa-edit mr-2"></i>
                                                <span>Editar</span>
                                            </button>
                                            <button onClick={() => { console.log("CLICK") }} className="text-white bg-red-500 hover:bg-red-800 text-[14px] px-[8px] py-1 rounded ml-3">
                                                <i className="fas fa-trash mr-2"></i>
                                                <span>Eliminar</span>
                                            </button>
                                        </span>
                                    </div>
                                </li>
                            )
                        })
                            : <div className='text-center'>No hay información para mostrar por el momento</div>
                    }
                </ul>
            </div>


            <Modal 
                isOpen={isOpenModalRol} closeModal={closeModalRol}
                action={() => closeModalRol()}
                title={`${registrarEditar ? 'Registrar Rol' : 'Editar Rol'}`}
            >
                    <div className="flex  items-center flex-wrap justify-center">
                        <div className="w-[500px] px-4">
                            <div className="flex flex-col justify-between w-full form-content">                        
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Nombre: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input 
                                            autoFocus={true}
                                            className={`${classNameInput} caret-input`}
                                            defaultValue={persona.nroDocumento}
                                            onChange={(e) => {
                                                editarValorPersona("nroDocumento", e.target.value);
                                            }}
                                            onKeyPress={(e) => soloDNI(e, e.target)} 
                                        />
                                    </div>
                                </div>
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Descripcion: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input  
                                            className={`${classNameInput} caret-input`} 
                                            defaultValue={persona.nomPersona} 
                                            onChange={(e) => editarValorPersona("nomPersona", e.target.value)} 
                                        />
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                    
                </div>
            </Modal>

            <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarPersona(persona.codPersona)}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                        </div>
                    </div>
                    
                
            </Modal>   


        </>
    )


}