import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from '../../components/modal/Modal';
import { soloCelular, soloDNI } from '../../../utils/utils';
import { UserContext } from '../../../context/provider/UserProvider';
import { Tooltip } from '../../components/utils/Tooltip';
import { useDocumentos } from '../../../hooks/useDocumentos';
import FloatingButton from '../../components/FloatingButton';
import { DatePickerABX } from '../../components/pickers/DatePicker';

export const Documentos = () => {
    const [ fechaEmision, setFechaEmision ] = useState(new Date())
    const [ fechaVencimiento,setFechaVencimiento ] = useState(new Date())
    const classNameInput = "text-gray-800 h-[30px] rounded px-4 w-full  text-l border focus:outline-none focus:ring-1 focus:ring-blue-500"   
    const {
        documentos,
        listarDocumentos,
        guardarEditarDocumento,
        documento,
        editarValorDocumento,
        obtenerDocumento,
        spinner,
        isOpen,
        closeModal,
        openModal
    } = useDocumentos()

    return (
        <>
        
            <h1 className=' text-2xl underline mt-5 ml-5'>Tipos de Documentos</h1>


                <div className="">
                    <ul className="cards">
                        {
                        documentos.length > 0 ? documentos.map((documento, i)=> {
                            return (
                                <li className='cursor-pointer'>
                                    <div className="details ">

                                        <span ><i className='fa fa-address-card mr-2'/>{documento.Nombre}</span>
                                        <span ><i className='fa fa-user mr-2'/>{documento.FechaEmision}</span>
                                        <span ><i className='fa fa-clock mr-2'/>{documento.FechaVencimiento}</span>
                                        <span >
                                            <button 
                                                onClick={() => {obtenerDocumento(documento.CodDocumento);}} 
                                                className="text-white bg-blue-500 hover:bg-blue-600 text-[14px] px-[5px] py-1 rounded"
                                            >
                                                <i className="fas fa-edit"/> Editar
                                            </button>
                                            <button  
                                                onClick={() => {obtenerDocumento(documento.CodDocumento);}} 
                                                className="text-white bg-red-500 hover:bg-red-800 text-[14px] px-[5px] py-1 rounded ml-3"
                                            >
                                                <i className="fas fa-trash"/>Eliminar
                                            </button>
                                        </span>
                                    </div>
                                </li>
                            )
                            })
                            : <div className='text-center'>No hay información para mostrar por el momento</div>
                        }
                    </ul>
                </div>



                <FloatingButton onButtonClick={()=>obtenerDocumento()}/>

            <Modal 
                isOpen={isOpen} closeModal={closeModal}
                action={() => guardarEditarDocumento()}
                title={`${documento.CodDocumento? 'Editar':'Nuevo'}`}
            >
                <div className="flex  items-center flex-wrap justify-center">
                        <div className="w-[500px] px-4">
                            <div className="flex flex-col justify-between w-full form-content">                        
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Nombre: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input  
                                            className={`${classNameInput} caret-black`} 
                                            defaultValue={documento.Nombre} 
                                            onChange={(e) => editarValorDocumento("Nombre", e.target.value)} 
                                        />
                                    </div>
                                </div>
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Fecha de Emision: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <DatePickerABX data-for={'FechaInicio'} date={fechaEmision} setDate={setFechaEmision}/>
                                    </div>
                                </div>
                                
                                <div className='lg:flex-col lg:w-full'>
                                    <label>Fecha de Vencimiento: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        
                                        <DatePickerABX data-for={'FechaInicio'} date={fechaEmision} setDate={setFechaEmision}/>
                                        {/* <input 
                                            className={`${classNameInput} caret-black`} 
                                            defaultValue={documento.FechaVencimiento}
                                            onChange={(e) => editarValorDocumento("FechaVencimiento", e.target.value)} 
                                        /> */}
                                    </div>
                                </div>

                                <div className='lg:flex-col lg:w-full'>
                                    <label>Destino: </label>
                                    <div className="flex items-center gap-4 w-[250px]">
                                        <input  
                                            className={`${classNameInput} caret-black`} 
                                            defaultValue={documento.Destino} 
                                            onChange={(e) => editarValorDocumento("Nombre", e.target.value)} 
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    
                </div>
            </Modal>

            {/* <Modal 
                isOpen={isOpenModalEliminar} closeModal={closeModalEliminar} action={() => eliminarPersona(persona.codPersona)}
                title= "Eliminar Persona"
                spinner={{}}
                textButtons={{ confirm: 'Si', denied: 'No' }}
            >
                    <div className="flex gap-8 items-center flex-wrap justify-center">
                        <div className="w-[400px] px-4">
                            <label className="text-center">¿Está seguro de eliminar la Persona? </label>
                        </div>
                    </div>
                    
                
            </Modal>   

 */}
        </>
    )


}