import React, { useContext, useState, useLazyRef, lazy, useMemo, useEffect } from 'react'
import {
    Switch,
    Route
} from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';

import { LayoutMaster } from '../modules/layout/LayoutMaster';
import { PrivateRoute } from '../components/PrivateRoute';

import { Usuarios } from '../pages/registros/Usuarios';
import { Documentos } from '../pages/registros/Documentos';
// import { Monitoreo } from '../pages/monitoreo/Monitoreo';
import { Permisos } from '../pages/configuraciones/Permisos';
import { Vehiculos } from '../pages/registros/Vehiculos';
import { TipoClases } from '../pages/registros/TipoClases';
import PageNotFound from '../pages/PageNotFound';
import { Roles } from '../pages/registros/Roles';
import { DashBoard } from '../pages/registros/DashBoard';
import { Matricula } from '../pages/registros/Matricula';
import { Programacion } from '../pages/registros/Programacion';
import { ReporteMatriculas } from '../pages/registros/ReporteMatriculas';
import { ReporteInstructores } from '../pages/registros/ReporteInstructores';
import { ReporteAsistencias } from '../pages/registros/ReporteAsistencias';
import { ReporteClases } from '../pages/registros/ReporteClases';
import { ReporteVehiculos } from '../pages/registros/ReporteVehiculos';






export function PrivateRoutes({ token }) {
    // const { stateUser } = useContext(UserContext);

    return (
        <>
            {
                token !== '' && localStorage.getItem('currentLocation') !== '/'
                && (
                    <LayoutMaster>
                        <Switch>
                            <PrivateRoute path='/vehiculos' component={Vehiculos} />
                            <PrivateRoute path='/registros-usuarios' component={Usuarios} />
                            <PrivateRoute path='/registros-documentos' component={Documentos} />
                            <PrivateRoute path='/matricula' component={Matricula} />
                            <PrivateRoute path='/dashboard' component={DashBoard} />
                            <PrivateRoute path='/clases' component={TipoClases} />
                            <PrivateRoute path='/roles' component={Roles} />
                            <PrivateRoute path='/programacion' component={Programacion} />

                            <PrivateRoute path='/reporte-matriculas' component={ReporteMatriculas} />
                            <PrivateRoute path='/reporte-instructores' component={ReporteInstructores} />
                            <PrivateRoute path='/reporte-asistencias' component={ReporteAsistencias} />
                            <PrivateRoute path='/reporte-vehiculos' component={ReporteVehiculos} />
                            <PrivateRoute path='/reporte-clases' component={ReporteClases} />

                            <PrivateRoute path='/documentos' component={Documentos} />
                            <PrivateRoute path='/roles' component={Roles} />
                            <PrivateRoute path='/configuraciones-permisos' component={Permisos} />                            
                                                                                
                            <Route path="*" component={PageNotFound} />

                        </Switch>
                    </LayoutMaster>
                )
        }
            
        </>
    )
}