import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal } from '../../components/modal/Modal';
import { useTipoUsuario } from '../../../hooks/usuarios/useTipoUsuario';
import { useUsuarios } from '../../../hooks/useUsuarios';
import { UserContext } from '../../../context/provider/UserProvider';
import { soloDNI, soloCelular, notify } from '../../../utils/utils';
import { useRoles } from '../../../hooks/useRoles';
import { Loading } from '../../components/Loading';
import { SearchInput } from '../../components/utils/SearchInput';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress, Paper, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { Cabecera } from '../../components/utils/Cabecera';
import { CustomSelect } from '../../components/forms/CustomSelect';
import { CustomTextField } from '../../components/forms/CustomTextField';
import { CustomImage } from '../../components/forms/CustomImagen';



export const Usuarios = () => {
    const [fechaNacimiento, setFechaNacimiento] = useState(new Date());
    const [selectedFile, setSelectedFile] = useState(null);
    const [fotoUrlUsuario, setFotoUrlUsuario] = useState('https://cdn-icons-png.flaticon.com/256/1535/1535791.png');
    const inputFoto = useRef();

    const [filteredUsuarios, setFilteredUsuarios] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const { roles } = useRoles();

    const tipoDocumentos = [
        { CodTipoDocumento: 1, NombreDocumento: 'DNI' },
        { CodTipoDocumento: 2, NombreDocumento: 'CE' },
    ];

     useEffect(() => {
        if (!selectedFile) {
            setFotoUrlUsuario(usuario?.Foto || 'https://cdn-icons-png.flaticon.com/256/1535/1535791.png');
            return;
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        setFotoUrlUsuario(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile,fotoUrlUsuario]); // usuario añadido aquí para garantizar que se renderiza cuando está disponible

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const objectUrl = URL.createObjectURL(file);
            setSelectedFile(file);
            setFotoUrlUsuario(objectUrl);
        }
    };
    useEffect(() => {
        const fechaComoCadena = fechaNacimiento.toLocaleDateString('es-ES', {
            day: '2-digit', month: '2-digit', year: 'numeric'
        });
        const partes = fechaComoCadena.split('/');
        const fechaFormatoISO = `${partes[2]}-${partes[1]}-${partes[0]}`;

        editarValorUsuario('FechaNacimiento', fechaFormatoISO);
    }, [fechaNacimiento]);

    const {
        usuarios,
        listarUsuarios,
        guardarEditarUsuario,
        usuario,
        editarValorUsuario,
        obtenerUsuario,
        spinner,
        isOpen,
        closeModal,
        openModal,
        obtenerDatosIdentidad,
        guardarUsuarioFoto,
        setFotoFile,
        cargandoBusquedaDNI
    } = useUsuarios();

    const { stateUser } = useContext(UserContext);

    async function tomarFotoYGuardar() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            const videoElement = document.createElement('video');
            document.body.appendChild(videoElement);
            videoElement.srcObject = stream;
            await videoElement.play();
            await new Promise(resolve => setTimeout(resolve, 2000));

            const canvas = document.createElement('canvas');
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

            stream.getTracks().forEach(track => track.stop());
            document.body.removeChild(videoElement);

            canvas.toBlob(blob => {
                const file = new File([blob], 'foto.jpg', { type: 'image/jpeg' });
                setSelectedFile(file);
                setFotoUrlUsuario(URL.createObjectURL(file));
                notify("Foto Guardada Correctamente", 'success');
            }, 'image/jpeg', 0.9);
        } catch (error) {
            console.error('Error al tomar la foto:', error);
            notify("Error : " + error, 'error');
        }
    }

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredUsuarios(usuarios);
        } else {
            const filtered = usuarios.filter(usuario =>
                ['Nombres', 'ApellidoPaterno', 'ApellidoMaterno', 'NombreRol', 'Celular', 'Direccion', 'Usuario']
                    .some(key => usuario[key].toLowerCase().includes(searchTerm))
            );
            setFilteredUsuarios(filtered);
        }
    }, [searchTerm, usuarios]);

    const filtros = [
        { nombre: 'NombreRol', label: 'Roles' },
        { nombre: 'Celular', label: 'Celular' },
    ];

    const handleGuardar = async () => {
        if (selectedFile) {
            // Aquí enviarías la imagen seleccionada al servidor
            await setFotoFile(selectedFile);
        } else {
            // Si no se ha seleccionado una nueva imagen, se mantiene la URL existente
            editarValorUsuario('Foto', fotoUrlUsuario);
        }

        guardarEditarUsuario(); // Guardar los cambios del usuario
    };

    return (
        <>
            <Cabecera
                titulo="Usuarios"
                onNuevo={() => {
                    setFotoUrlUsuario('https://cdn-icons-png.flaticon.com/256/1535/1535791.png');
                    openModal();
                }}
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                data={usuarios}
                updateFilteredData={setFilteredUsuarios}
                searchFields={['Nombres', 'ApellidoPaterno', 'ApellidoMaterno', 'NombreRol', 'Celular', 'Direccion', 'Usuario']}
                placeholder="Buscar en usuarios..."
                nuevoTexto="Nuevo Usuario"
                filtros={filtros}
            />

            <div className="">
                <ul className="cards">
                    {filteredUsuarios.length > 0 ? filteredUsuarios.map((u, i) => (
                        <li className='cursor-pointer' key={i}>
                            <img className='rounded-full' src={u.Foto} alt={`Foto de ${u.Nombres}`} />
                            <div className="details ">
                                <span><i className='fa fa-user mr-2' />{u.Nombres} {u.ApellidoPaterno} {u.ApellidoMaterno}</span>
                                <span><i className='fa fa-address-card mr-2' />{u.NombreRol}</span>
                                <span><i className='fa fa-mobile mr-2' />{u.Celular}</span>
                                <span><i className='fa fa-user-circle-o mr-2' />{u.Usuario}</span>
                                <span>
                                    <button onClick={() => { obtenerUsuario(u.Usuario); }} className="text-white bg-blue-500 hover:bg-blue-600 text-[14px] px-[8px] py-1 rounded">
                                        <i className="fas fa-edit mr-2"></i>
                                        <span>Editar</span>
                                    </button>
                                    <button onClick={() => { obtenerUsuario(u.Usuario); }} className="text-white bg-red-500 hover:bg-red-800 text-[14px] px-[8px] py-1 rounded ml-3">
                                        <i className="fas fa-trash mr-2"></i>
                                        <span>Eliminar</span>
                                    </button>
                                </span>
                            </div>
                        </li>
                    )) : <div className='text-center'>No hay información para mostrar por el momento</div>}
                </ul>
            </div>

            {cargandoBusquedaDNI && <Loading />}

            <Modal
                isOpen={isOpen} closeModal={closeModal} action={handleGuardar}
                title={`${usuario.CodUsuario === 0 ? 'Registrar usuario' : 'Editar usuario'}`}
                spinner={spinner}
            >
                <div className="lg:flex-col lg:max-h-[500px] lg:overflow-y-auto text-l flex justify-center items-start containerScroll bg-gray-900 text-white p-4 rounded-lg">
                    <div className="grid grid-cols-3 lg:grid-cols-1 gap-4 w-full">
                        {/* Columna 1 */}
                        <div className="flex flex-col items-center">
                            <CustomImage
                                imageUrl={fotoUrlUsuario}
                                onImageChange={onSelectFile}
                            />

                            <CustomSelect
                                label="Tipo Documento"
                                value={usuario.CodTipoDocumento || 1}
                                onChange={(e) => editarValorUsuario('CodTipoDocumento', e.target.value)}
                                options={tipoDocumentos.map(doc => ({
                                    value: doc.CodTipoDocumento,
                                    label: doc.NombreDocumento
                                }))}
                            />

                            <div className="flex items-center gap-4 w-full mt-4">
                                <CustomTextField
                                    label="Número"
                                    value={usuario.DNI}
                                    onChange={(e) => editarValorUsuario('DNI', e.target.value)}
                                    onKeyPress={(e) => soloDNI(e, e.target)}
                                />
                                <button onClick={() => obtenerDatosIdentidad(usuario.DNI)} className="hover:bg-green-500 bg-green-600 p-2 rounded px-2 ml-2">
                                    <i className="fa fa-search text-white"></i>
                                </button>
                            </div>
                        </div>

                        {/* Columna 2 */}
                        <div className="flex flex-col gap-4">
                            <CustomTextField
                                label="Nombres"
                                value={usuario.Nombres}
                                onChange={(e) => editarValorUsuario('Nombres', e.target.value)}
                            />

                            <CustomTextField
                                label="Apellido Paterno"
                                value={usuario.ApellidoPaterno}
                                onChange={(e) => editarValorUsuario('ApellidoPaterno', e.target.value)}
                            />

                            <CustomTextField
                                label="Apellido Materno"
                                value={usuario.ApellidoMaterno}
                                onChange={(e) => editarValorUsuario('ApellidoMaterno', e.target.value)}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                label="Fecha Nacimiento"
                                type="date"
                                value={usuario.FechaNacimiento}
                                onChange={(e) => editarValorUsuario('FechaNacimiento', e.target.value)}
                                className="bg-gray-700 text-white rounded-t"
                                InputLabelProps={{ style: { color: '#DEDEDE' } }}
                                InputProps={{ style: { color: 'white' } }}
                            />

                            <CustomTextField
                                label="Dirección"
                                value={usuario.Direccion}
                                onChange={(e) => editarValorUsuario('Direccion', e.target.value)}
                            />
                        </div>

                        {/* Columna 3 */}
                        <div className="flex flex-col gap-4 text-white">
                            <CustomTextField
                                label="Email"
                                value={usuario.Email}
                                onChange={(e) => editarValorUsuario('Email', e.target.value)}
                            />

                            <CustomTextField
                                label="Celular"
                                value={usuario.Celular}
                                onChange={(e) => editarValorUsuario('Celular', e.target.value)}
                                onKeyPress={(e) => soloCelular(e, e.target)}
                            />

                            <CustomSelect
                                label="Rol"
                                value={usuario.CodRol}
                                onChange={(e) => editarValorUsuario('CodRol', e.target.value)}
                                options={roles.map(rol => ({
                                    value: rol.CodRol,
                                    label: rol.NombreRol
                                }))}
                            />

                            <CustomTextField
                                label="Usuario"
                                value={usuario.Usuario}
                                onChange={(e) => editarValorUsuario('Usuario', e.target.value)}
                            />

                            <CustomTextField
                                label="Clave"
                                type="password"
                                value={usuario.Clave}
                                onChange={(e) => editarValorUsuario('Clave', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};
