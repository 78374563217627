import { useState, useEffect, useContext } from "react";
import { notify } from "../utils/utils";
import { AuthFetch } from "../services/api";
import { useModal } from "./useModal";
import { useSpinner } from "./useSpinner";
import { UserContext } from "../context/provider/UserProvider";

const urlTipoClases = process.env.REACT_APP_PROMETHEUS_API + "/tipoClase";

export const useTipoClases = () => {
    const { stateUser , signOut } = useContext(UserContext);
    const tipoClaseDefault = {
        CodTipoClase: 0,
        Nombre: "",
        Descripcion: "",
        Monto: 0,
        CantidadHoras: 0,
        CodTipoVehiculo: 0
    }
    const [ tipoClases, setTiposClases ] = useState([]);
    const [ tipoClase, setTipoClase ] = useState(tipoClaseDefault);
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner();
    const [ isOpen, openModal, closeModal] = useModal();
    
    useEffect(() => {
        listarTipoClases();
    }, []);

    const listarTipoClases = async () => {
        console.log("INGRESO A LA FUNCION LISTAR Tipos Clases ")
        const response = await AuthFetch({
            url: urlTipoClases + '?'+ new URLSearchParams({
                database  : stateUser.bdEscuela,
            })
        });
        if (response.isValid) {
            console.log("RESPONSE useTipoClases -> ", response.content)
            setTiposClases(response.content);
        } else {
            notify(response.content, 'error');
        }
    }


    const obtenerTipoClase= async (codTipoClase) => {
        if (codTipoClase) {
            const response = await AuthFetch({
                url: urlTipoClases + '/obtenerTipoClase?'+ new URLSearchParams({
                    cod_tipo_clase : codTipoClase,
                    database  : stateUser.bdEscuela,
                })
            });
            console.log("RESPONSE OBTENER TIPO CLASE -> ", response)
            if (response.isValid) {
                setTipoClase(response.content);
            } else {
                notify(response.content, 'error');
            }
        } else {
            setTipoClase(tipoClaseDefault);
        }
        openModal();
    }
    
    
    
    const guardarEditarTipoClase = async () => {
        const esGuardar = tipoClase.CodTipoClase == 0;
        const response = await AuthFetch({
            url: urlTipoClases ,
            method: esGuardar ? 'POST' : 'PUT',
            body : JSON.stringify({
                ...(!esGuardar && { CodTipoClase: tipoClase.CodTipoClase }),
                Nombre: tipoClase.Nombre,
                Descripcion: tipoClase.Descripcion,
                Monto: tipoClase.Monto,
                CantidadHoras: tipoClase.CantidadHoras,
                CodTipoVehiculo: tipoClase.CodTipoVehiculo,
                BDEscuela  : stateUser.bdEscuela,
            })
        });
        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            setTipoClase(tipoClaseDefault);
            await listarTipoClases();
            closeModal()
        }
    }

    const editarValorTipoClase = (key,value) =>{
        console.log("EDITAR VALOR Tipo Clase -> ", key,value)
        setTipoClase(tipoClase => {
            return {
                ...tipoClase,
                [key]: value
            }
        });
    }     
    return {
        tipoClase,
        listarTipoClases,
        guardarEditarTipoClase,
        tipoClases,
        editarValorTipoClase,
        obtenerTipoClase,
        spinner,
        isOpen,
        closeModal,
        openModal
    };
}